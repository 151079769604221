import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  UploadFile,
  UploadProps,
  message,
} from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "../../../settings";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";

function DirectorMessage() {
  const [messageId, setMessageId] = useState();
  const [messages, setMessages] = useState<string>();
  const [type, setType] = useState("DIRECTOR_MESSAGE");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [apiLoader, setApiLoader] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [messangerPhotoName, setMessangerPhotoName] = useState("");
  const [messageForm] = Form.useForm();

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleRemoveItemSlider = async (file: UploadFile) => {
    setMessangerPhotoName("");
    setFileList([]);
    return true;
  };

  const props: UploadProps = {
    name: "file",
    // beforeUpload: beforeUpload,
    fileList: fileList,
    onPreview: handlePreview,
    action: `${API_URL}/image-upload`,
    onRemove: handleRemoveItemSlider,

    onChange(info) {
      setFileList(info.fileList);
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (
        info.file.percent === 100 &&
        status === "done" &&
        info.file.response &&
        info.file.response.file_name
      ) {
        setMessangerPhotoName(info.file.response.file_name);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {},
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = () => setPreviewOpen(false);

  useEffect(() => {
    getMessageInfo();
    return () => {};
  }, []);

  const getMessageInfo = () => {
    setApiLoader(true);
    axios
      .get(`${API_URL}/company-messages-by-type/${type}`)
      .then((response) => {
        setApiLoader(false);
        setMessages(response.data.message);
        setMessageId(response.data.id);
        messageForm.setFieldValue("message", response.data.message);
        messageForm.setFieldValue(
          "messenger_name",
          response.data.messenger_name
        );
        messageForm.setFieldValue(
          "messenger_designation",
          response.data.messenger_designation
        );
        setMessangerPhotoName(response.data.messenger_photo_name);
    
        if (response.data.messenger_photo_name) {
            setFileList([{
                'uid': response.data.id,
                'name': response.data.messenger_photo_name,
                'status': 'done',
                'url': API_URL + '/image-download/' + response.data.messenger_photo_name,
            }]);
        }
      })
      .catch((err) => {
        // Handle error
        console.log("server error", err);
        setApiLoader(false);
      });
  };

  const onSubmitForm = () => {
    setApiLoader(true);

    if (messageId) {
      axios
        .put(`${API_URL}/company-messages/${messageId}`, {
          message_type: type,
          message: messages,
          messenger_name: messageForm.getFieldValue("messenger_name"),
          messenger_designation: messageForm.getFieldValue(
            "messenger_designation"
          ),
          messenger_photo_name: messangerPhotoName,
        })
        .then((response) => {
          message.success("Successfully updated.");
          setApiLoader(false);
        })
        .catch((err) => {
          // Handle error
          console.log("server error");
          setApiLoader(false);
        });
    } else {
      axios
        .post(`${API_URL}/company-messages`, {
          message_type: type,
          message: messages,
          messenger_name: messageForm.getFieldValue("messenger_name"),
          messenger_designation: messageForm.getFieldValue(
            "messenger_designation"
          ),
          messenger_photo_name: messangerPhotoName,
        })
        .then((response) => {
          setMessageId(response.data.id);
          message.success("Successfully created.");
          setApiLoader(false);
        })
        .catch((err) => {
          // Handle error
          console.log("server error");
          setApiLoader(false);
        });
    }
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Card title="Chairman's Message" bordered={true}>
            <Spin spinning={apiLoader}>
              <Col span={9}>
                <Row>
                  <Col span={24}>
                    <div>
                        <Dragger
                          maxCount={1}
                          listType="picture-card"
                          {...props}
                          accept={"image/*"}
                          multiple={false}
                          showUploadList={{
                            showPreviewIcon: true,
                            showRemoveIcon: true,
                            showDownloadIcon: true,
                          }}
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                          <p className="ant-upload-hint">
                            Support for a single image upload. Strictly
                            prohibited from uploading company data or other
                            banned files.
                          </p>
                        </Dragger>
                        <Modal
                          open={previewOpen}
                          footer={null}
                          onCancel={handleCancel}
                        >
                          <img
                            alt="slider"
                            style={{ width: "100%" }}
                            src={previewImage}
                          />
                        </Modal>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Form
                form={messageForm}
                name="messageForm"
                labelCol={{ span: 8 }}
                initialValues={{ remember: true }}
                onFinish={onSubmitForm}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row gutter={12}>
                  <Col sm={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item label="Messanger Name" name="messenger_name">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col sm={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      label="Messanger Designation"
                      name="messenger_designation"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <CKEditor
                  editor={ClassicEditor}
                  data={messages}
                  onReady={(editor) => {
                    // console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setMessages(data);
                  }}
                  onBlur={(event, editor) => {
                    // console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    // console.log('Focus.', editor);
                  }}
                />
                {/* </Form.Item> */}

                <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                  <Button className="mt-3" type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default DirectorMessage;
