import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import { Button, Col, Popconfirm, Row, Space, message } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IItem from "../../../../interfaces/IItem";
import { API_URL } from "../../../../settings";

function Items() {
  var [tableLoadingSpin, setTableSpinLoading] = useState(false);
  const [items, setItems] = useState<IItem[]>([]);

  useEffect(() => {
    getItemList();

    return () => {};
  }, []);

  const getItemList = () => {
    setTableSpinLoading(true);
    axios
      .get(`${API_URL}/items`)
      .then((response) => {
        response.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
        });
        setItems(response.data);
        setTableSpinLoading(false);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
        setTableSpinLoading(false);
      });
  };

  // table rendering settings
  const itemColumns: ColumnsType<IItem> = [
    {
      title: "Item ID",
      dataIndex: "item_id",
      key: "item_id",
    },
    {
      title: "Item Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Project Name",
      dataIndex: ["project", "project_name"],
      key: "project",
    },

    {
      title: "Project Category",
      dataIndex: ["project", "project_category", "name"],
      key: "projectName",
    },
    {
      title: "Net Price",
      dataIndex: "net_price",
      key: "netPrice",
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (_: any, record: IItem) =>
        moment.utc(record.created_at).local().format("DD-MMM-YYYY"),
    },
    {
      title: "Modified Date",
      dataIndex: "lastModifiedDate",
      key: "lastModifiedDate",
      render: (_: any, record: IItem) =>
        moment.utc(record.updated_at).local().format("DD-MMM-YYYY"),
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: IItem) => (
        <Space size="middle">
          <Link to={`/items/${record.id}`}>
            <EditTwoTone />
          </Link>
          {/* <a onClick={() => viewAction(record.id)}>View</a>
                    <a onClick={() => updateAction(record.id)}>Update</a>*/}
          <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={() => deletePopConfirm(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <a>
              <DeleteTwoTone />
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const deletePopConfirm = (itemId: any) => {
    axios
      .delete(`${API_URL}/items/${itemId}`)
      .then((response) => {
        getItemList();
        message.success("Deleted Successfully.");
      })
      .catch((err) => {
        console.log("server error", err);
      });
  };

  return (
    <>
      <Row>
        <Col md={24}>
          <div>
            <Title level={4}>Item List</Title>
            <Link to="/item">
              <Button type="primary">Create</Button>
            </Link>
            <Table
              loading={tableLoadingSpin}
              size="small"
              dataSource={items}
              columns={itemColumns}
              scroll={{ x: "max-content" }} // Enables horizontal scrolling on smaller screens
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Items;
