import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import { Button, Col, Popconfirm, Row, Space, message } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IProject from "../../../../interfaces/IProject";
import { API_URL } from "../../../../settings";

function Projects() {
  var [tableLoadingSpin, setTableSpinLoading] = useState(false);
  const [projects, setProjects] = useState<IProject[]>([]);

  useEffect(() => {
    getProjectList();

    return () => {};
  }, []);

  const getProjectList = () => {
    setTableSpinLoading(true);
    axios
      .get(`${API_URL}/projects`)
      .then((response) => {
        response.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
        });
        setProjects(response.data);
        setTableSpinLoading(false);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
        setTableSpinLoading(false);
      });
  };

  const deletePopConfirm = (projectId: any) => {
    axios
      .delete(`${API_URL}/projects/${projectId}`)
      .then((response) => {
        getProjectList();
        message.success("Deleted Successfully.");
      })
      .catch((err) => {
        console.log("server error", err);
      });
  };

  const viewAction = (id: number) => {};
  // table rendering settings
  const projectColumns: ColumnsType<IProject> = [
    {
      title: "Project Id",
      dataIndex: "project_id",
      key: "project_id",
    },
    {
      title: "Project Name",
      dataIndex: "project_name",
      key: "project_name",
    },
    {
      title: "Project Category",
      dataIndex: ["project_category", "name"],
      key: "project_category",
    },
    // {
    //     title: 'Status',
    //     dataIndex: 'status',
    //     key: 'status',
    //     render: (_: any, record: IProject) => {
    //         if (record.activeStatus) {
    //             return (
    //                 <span>
    //                     <CheckCircleTwoTone twoToneColor="#52c41a" /> Active
    //                 </span>
    //             )
    //         } else {
    //             return (
    //                 <span>
    //                     <CheckCircleTwoTone twoToneColor="#eb2f96" /> InActive
    //                 </span>
    //             )
    //         }

    //     },
    // },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (_: any, record: IProject) =>
        moment.utc(record.created_at).local().format("DD-MMM-YYYY"),
    },
    {
      title: "Modified Date",
      dataIndex: "lastModifiedDate",
      key: "lastModifiedDate",
      render: (_: any, record: IProject) =>
        moment.utc(record.updated_at).local().format("DD-MMM-YYYY"),
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: IProject) => (
        <Space size="middle">
          <Link to={`/project/${record.id}`}>
            <EditTwoTone />
          </Link>
          {/* <a onClick={() => viewAction(record.id)}><EyeTwoTone /></a> */}
          <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={() => deletePopConfirm(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <a>
              <DeleteTwoTone />
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col md={24}>
          <div>
            <Title level={4}>Project List</Title>
            <Link to={"/project"}>
              <Button type="primary">Create</Button>
            </Link>
            <Table
              loading={tableLoadingSpin}
              size="small"
              dataSource={projects}
              columns={projectColumns}
              scroll={{ x: "max-content" }} // Enables horizontal scrolling on smaller screens
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Projects;
