import {
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Flex,
  Layout,
  MenuProps,
  Row,
  Space,
  theme,
} from "antd";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import CorporateProfile from "../AboutUs/CorporateProfile";
import MissionAndVision from "../AboutUs/MissionVision";
import AuthUser from "../Auth/AuthUser";
import Contact from "./Contact/ContactComponent";
import Dashboard from "./Dashboard/DashboardComponent";
import ChairmanMessage from "./Messages/ChairmanMessage";
import DirectorMessage from "./Messages/DirectorMessage";
import ManagingDirectorMessage from "./Messages/ManagingDirectorMessage";
import Configuration from "./RealEstate/Configuration/Configuration";
import Facing from "./RealEstate/Facing/Facing";
import Item from "./RealEstate/Item/Item";
import Items from "./RealEstate/Item/Items";
import PlotPosition from "./RealEstate/PlotPosition/PlotPosition";
import Project from "./RealEstate/Project/Project";
import Projects from "./RealEstate/Project/Projects";
import ProjectCategory from "./RealEstate/ProjectCategory/ProjectCategory";
import ProjectStatus from "./RealEstate/ProjectStatus/ProjectSatus";
import ProjectType from "./RealEstate/ProjectType/ProjectType";
import Uom from "./RealEstate/Uom/Uom";
import Gallery from "./Gallery/Gallery";
import Galleries from "./Gallery/Galleries";
import Csr from "./Csr/Csr";
import Csrs from "./Csr/Csrs";
import Company from "./Company/Company";
import Companies from "./Company/Companies";
import Region from "./RealEstate/Region/Region";
import SubRegion from "./RealEstate/Region/SubRegion";

const { Header, Sider, Content } = Layout;
interface ContentComponentProps {
    onToggleCollapse: (value: boolean) => void;
    collapsed: boolean;
  }
  
  const ContentComponent: React.FC<ContentComponentProps> = ({ onToggleCollapse,collapsed }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { user, logout } = AuthUser();

  const items: MenuProps["items"] = [
    {
      label: "Logout",
      key: "3",
    },
  ];

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    logout();
  };

  return (
    <>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <Row>
            <Col span={22}>
              <Flex justify="space-between">
                <Button
                  type="text"
                  icon={
                    collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                  }
                  onClick={() => {onToggleCollapse(!collapsed)}}
                  style={{
                    fontSize: "16px",
                    width: 64,
                    height: 64,
                  }}
                />
                <div>
                  <Dropdown
                    menu={{ items, onClick: handleMenuClick }}
                    trigger={["click"]}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>
                        {user?.name}
                        <DownOutlined />
                      </Space>
                    </a>
                  </Dropdown>
                </div>
              </Flex>
            </Col>
          </Row>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 360,
            background: colorBgContainer,
          }}
        >
          <div>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="contact" element={<Contact />} />
              <Route path="uom" element={<Uom />} />
              <Route path="facing" element={<Facing />} />
              <Route path="plot-position" element={<PlotPosition />} />
              <Route path="real-estate-config" element={<Configuration />} />
              <Route path="project" element={<Project />} />
              <Route path="project/:id" element={<Project />} />
              <Route path="projects" element={<Projects />} />
              <Route path="project-type" element={<ProjectType />} />
              <Route path="project-category" element={<ProjectCategory />} />
              <Route path="project-status" element={<ProjectStatus />} />
              <Route path="item" element={<Item />} />
              <Route path="items/:id" element={<Item />} />
              <Route path="items" element={<Items />} />
              <Route path="message-director" element={<DirectorMessage />} />
              <Route
                path="message-managing-director"
                element={<ManagingDirectorMessage />}
              />
              <Route path="message-chairman" element={<ChairmanMessage />} />
              <Route path="corporate-profile" element={<CorporateProfile />} />
              <Route path="mission-and-vision" element={<MissionAndVision />} />
              <Route path="gallery" element={<Gallery />} />
              <Route path="galleries/:id" element={<Gallery />} />
              <Route path="galleries" element={<Galleries />} />
              <Route path="csrs/:id" element={<Csr />} />
              <Route path="csrs" element={<Csrs />} />
              <Route path="csr" element={<Csr />} />
              <Route path="companies/:id" element={<Company />} />
              <Route path="companies" element={<Companies />} />
              <Route path="company" element={<Company />} />
              <Route path="region" element={<Region />} />
              <Route path="sub-region" element={<SubRegion />} />
            </Routes>
          </div>
        </Content>
      </Layout>
    </>
  );
}

export default ContentComponent;
