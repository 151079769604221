import { Button, Card, Col, Form, Input, Row, message, theme } from 'antd';
import { Content } from 'antd/es/layout/layout';
import AuthUser from './AuthUser';

function Login() {

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const { http, setToken } = AuthUser();

    const onFinish = (values: any) => {
        console.log(values)
        // api call
        http.post('/login', {
            username: values.username,
            password: values.password
        }).then((res) => {
            console.log(res);
            setToken(res.data.user, res.data.access_token);
        }).catch(err => {
            // Handle error
            console.log("server error");
            message.error("Login failed!");
        });
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const contentStyle: React.CSSProperties = {
        backgroundColor: colorBgContainer,
    };


    return (
        <>
            <Content style={contentStyle}>
                <Row justify={'center'}>
                    <Col xs={23} sm={23} md={10} lg={8}>
                        <Card className='mt-5 ' style={{ maxWidth: 500, paddingTop: 25 }}>
                            <Form
                                name="basic"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label="Username"
                                    name="username"
                                    rules={[{ required: true, message: 'Please input your username!' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[{ required: true, message: 'Please input your password!' }]}
                                >
                                    <Input.Password />
                                </Form.Item>
                                <Form.Item wrapperCol={{ offset: 10 }}>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>

            </Content>

        </>
    )
}

export default Login;
