import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Upload,
  UploadFile,
  message,
  notification,
} from "antd";
import { RcFile, UploadProps } from "antd/es/upload";
import axios from "axios";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import IGallerySlider from "../../../interfaces/IGallerySlider";
import { API_URL } from "../../../settings";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

function Gallery() {
  const navigate = useNavigate();
  const [notificationApi, contextHolder] = notification.useNotification();
  //   const [notificationApi, contextHolder] = notification.useNotification();

  const [galleryCategoryId, setProjectCategoryId] = useState(1);
  const [sliderLoading, setSliderLoading] = React.useState<boolean>(false);
  var [formState, setFormState] = useState("CREATE");
  const [formSubmitButtonText, setFormSubmitButtonText] = useState("Create");
  const [galleryDetails, setGalleryDetails] = useState<string>();
  const [galleryDetailsBn, setGalleryDetailsBn] = useState<string>();
  const [galleryLoading, setGalleryLoading] = React.useState<boolean>(false);
  const [galleryForm] = Form.useForm();
  const [galleryThumbnailImageName, setGalleryThumbnailImageName] =
    useState("");
  const { Dragger } = Upload;
  const [gallerySliders, setGallerySliders] = useState<IGallerySlider[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [branGalleryfileList, setBranGalleryFileList] = useState<UploadFile[]>(
    []
  );

  const handleRemoveGallerySlider = async (file: UploadFile) => {
    setGallerySliders(gallerySliders.filter((x) => x.image_name !== file.name));
    return true;
  };

  const onResetGalleryForm = () => {
    galleryForm.resetFields();
  };

  let { id } = useParams();

  const onFinishGalleryForm = (values: any) => {

    if (!id) {
      axios
        .post(`${API_URL}/galleries`, {
          title: galleryForm.getFieldValue("title"),
          description: galleryDetails,
          description_bn: galleryDetailsBn,
          thumbnail_name: galleryThumbnailImageName,
          publish_flag: galleryForm.getFieldValue("publish_flag"),
          gallery_sliders: gallerySliders,
        })
        .then((response) => {
          navigate("/galleries");
        })
        .catch((err) => {
          console.log("server error", err);
        });
    } else {
      axios
        .put(`${API_URL}/galleries/${id}`, {
          title: galleryForm.getFieldValue("title"),
          description: galleryDetails,
          description_bn: galleryDetailsBn,
          thumbnail_name: galleryThumbnailImageName,
          publish_flag: galleryForm.getFieldValue("publish_flag"),
          gallery_sliders: gallerySliders,
        })
        .then((response) => {
          navigate("/galleries");
        })
        .catch((err) => {
          console.log("server error", err);
        });
    }
    // }
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleRemoveBrandGallerySlider = async (file: UploadFile) => {
    setGalleryThumbnailImageName("");
    setBranGalleryFileList([]);
    return true;
  };

  const getGallery = () => {
    setGalleryLoading(true);
    axios
      .get(`${API_URL}/galleries/${id}`)
      .then((response) => {
        setGalleryDetails(response.data.description);
        setGalleryDetailsBn(response.data.description_bn);
        setGalleryThumbnailImageName(response.data.thumbnail_name);
        galleryForm.setFieldsValue({
          publish_flag: response.data.publish_flag,
        });
        if (response.data.thumbnail_name) {
          setBranGalleryFileList([
            {
              uid: response.data.id,
              name: response.data.thumbnail_name,
              status: "done",
              url: API_URL + "/image-download/" + response.data.thumbnail_name,
            },
          ]);
        }

        galleryForm.setFieldsValue({
          title: response.data.title,
          description: response.data.description,
        });
        response.data.gallery_sliders.map(
          (x: { [x: string]: any; id: any }) => {
            x["key"] = x.id;
            x["uid"] = x.id;
            x["name"] = x.image_name;
            x["status"] = "done";
            x["url"] = API_URL + "/image-download/" + x.image_name;
          }
        );

        setFileList(response.data.gallery_sliders);
        setGallerySliders(response.data.gallery_sliders);
        setGalleryLoading(false);
        setFormSubmitButtonText("Change");
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
        setGalleryLoading(false);
      });
  };

  const onResetProjectForm = () => {
    galleryForm.resetFields();
  };

  useEffect(() => {
    onResetProjectForm();
    if (id) {
      getGallery();
      setFormState("UPDATE");
    }
    return () => {};
  }, []);

  const handleChange: UploadProps["onChange"] = (info) => {
    setFileList(info.fileList);
    const { status } = info.file;

    if (
      info.file.percent === 100 &&
      status === "done" &&
      info.file.response &&
      info.file.response.file_name
    ) {
      const gallerySliderObject = {
        image_name: info.file.response.file_name,
        gallery_id: id ? parseInt(id) : undefined,
      };
      setGallerySliders((prevState) => [...prevState, gallerySliderObject]);
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const galleryThumnailprops: UploadProps = {
    name: "file",
    // beforeUpload: beforeUpload,
    fileList: branGalleryfileList,
    // onPreview: handlePreview,
    action: `${API_URL}/image-upload`,
    onRemove: handleRemoveBrandGallerySlider,

    onChange(info) {
      setBranGalleryFileList(info.fileList);
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (
        info.file.percent === 100 &&
        status === "done" &&
        info.file.response &&
        info.file.response.file_name
      ) {
        setGalleryThumbnailImageName(info.file.response.file_name);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {},
  };

  return (
    <>
      {contextHolder}
      <Form
        layout="vertical"
        name="realEstateConfigForm"
        form={galleryForm}
        initialValues={{ remember: 1 }}
        autoComplete="off"
        onFinish={onFinishGalleryForm}
      >
        <Row gutter={10}>
          <Col span={15}>
            <Card title="Gallery Information" bordered={true}>
              <Spin spinning={galleryLoading}>
                <Row gutter={10}>
                  <Col span={16}>
                    <Form.Item
                      name="title"
                      label="Title"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder="Title" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="publish_flag"
                      label="Publish Status"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      initialValue={0}
                    >
                      <Select
                        options={[
                          { value: 0, label: "Draft" },
                          { value: 1, label: "Publish" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name="description" label="Description">
                      <CKEditor
                        editor={ClassicEditor}
                        data={galleryDetails ? galleryDetails : ""}
                        onChange={(event, editor) => {
                          setGalleryDetails(editor.getData());
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="descriptionBn"
                      label="Description (Bangla)"
                    >
                      <CKEditor
                        editor={ClassicEditor}
                        data={galleryDetailsBn ? galleryDetailsBn : ""}
                        onChange={(event, editor) => {
                          setGalleryDetailsBn(editor.getData());
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item>
                      <Space>
                        <Button type="primary" htmlType="submit">
                          {formSubmitButtonText}
                        </Button>
                        {!id && (
                          <Button
                            htmlType="button"
                            onClick={onResetGalleryForm}
                          >
                            Reset
                          </Button>
                        )}

                        <Link
                          className="text-decoration-none"
                          to={"/galleries"}
                        >
                          {" "}
                          Cancel
                        </Link>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Spin>
            </Card>
          </Col>
          <Col span={9}>
            <Row className="mb-2">
              <Col span={24}>
                <Card title="Thumbnail" bordered={true}>
                  <Dragger
                    maxCount={1}
                    listType="picture-card"
                    {...galleryThumnailprops}
                    accept={"image/*"}
                    multiple={false}
                    showUploadList={{
                      showPreviewIcon: true,
                      showRemoveIcon: true,
                      showDownloadIcon: true,
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Support for a single image upload. Strictly prohibited
                      from uploading company data or other banned files.
                    </p>
                  </Dragger>
                  <Modal
                    open={previewOpen}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <img
                      alt="slider"
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  </Modal>
                </Card>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col span={24}>
                <Card title="Sliders" bordered={true}>
                  <Spin spinning={sliderLoading}>
                    <div>
                      <Upload
                        action={`${API_URL}/image-upload`}
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onRemove={handleRemoveGallerySlider}
                        onChange={handleChange}
                        accept={"image/*"}
                        maxCount={8}
                        multiple={true}
                        showUploadList={{
                          showPreviewIcon: true,
                          showRemoveIcon: true,
                          showDownloadIcon: true,
                        }}
                      >
                        {fileList.length >= 8 ? null : uploadButton}
                      </Upload>
                      <Modal
                        open={previewOpen}
                        footer={null}
                        onCancel={handleCancel}
                      >
                        <img
                          alt="slider"
                          style={{ width: "100%" }}
                          src={previewImage}
                        />
                      </Modal>
                    </div>
                  </Spin>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default Gallery;
