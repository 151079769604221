import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import { Button, Col, Popconfirm, Row, Space, message } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Gallery from "./Gallery";
import { API_URL } from "../../../settings";
import IGallery from "../../../interfaces/IGallery";

function Galleries() {
  var [tableLoadingSpin, setTableSpinLoading] = useState(false);
  const [galleries, setGalleries] = useState<IGallery[]>([]);

  useEffect(() => {
    getGallerryList();

    return () => {};
  }, []);

  const getGallerryList = () => {
    setTableSpinLoading(true);
    axios
      .get(`${API_URL}/galleries`)
      .then((response) => {
        response.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
        });
        setGalleries(response.data);
        setTableSpinLoading(false);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
        setTableSpinLoading(false);
      });
  };

  const deletePopConfirm = (gallerieId: any) => {
    axios
      .delete(`${API_URL}/galleries/${gallerieId}`)
      .then((response) => {
        getGallerryList();
        message.success("Deleted Successfully.");
      })
      .catch((err) => {
        console.log("server error", err);
      });
  };

  const viewAction = (id: number) => {};
  // table rendering settings
  const gallerieColumns: ColumnsType<IGallery> = [
    // {
    //     title: 'Gallerry Id',
    //     dataIndex: 'gallerie_id',
    //     key: 'gallerie_id',
    // },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    // {
    //     title: 'Status',
    //     dataIndex: 'status',
    //     key: 'status',
    //     render: (_: any, record: Gallery) => {
    //         if (record.activeStatus) {
    //             return (
    //                 <span>
    //                     <CheckCircleTwoTone twoToneColor="#52c41a" /> Active
    //                 </span>
    //             )
    //         } else {
    //             return (
    //                 <span>
    //                     <CheckCircleTwoTone twoToneColor="#eb2f96" /> InActive
    //                 </span>
    //             )
    //         }

    //     },
    // },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (_: any, record: IGallery) =>
        moment.utc(record.created_at).local().format("DD-MMM-YYYY"),
    },
    {
      title: "Modified Date",
      dataIndex: "lastModifiedDate",
      key: "lastModifiedDate",
      render: (_: any, record: IGallery) =>
        moment.utc(record.updated_at).local().format("DD-MMM-YYYY"),
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: IGallery) => (
        <Space size="middle">
          <Link to={`/galleries/${record.id}`}>
            <EditTwoTone />
          </Link>
          <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={() => deletePopConfirm(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <a>
              <DeleteTwoTone />
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col md={24}>
          <div>
            <Title level={4}>Gallerry List</Title>
            <Link to={"/gallery"}>
              <Button type="primary">Create</Button>
            </Link>
            <Table
              loading={tableLoadingSpin}
              size="small"
              dataSource={galleries}
              columns={gallerieColumns}
              scroll={{ x: "max-content" }} // Enables horizontal scrolling on smaller screens
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Galleries;
