import { Layout, theme } from 'antd';
import 'antd/dist/antd.js';
import 'antd/dist/reset.css';
import React, { useState } from 'react';
import './App.css';
import './assets/css/bootstrap.min.css';
import AuthUser from './components/Auth/AuthUser';
import Login from './components/Auth/Login';
import ContentComponent from './components/Content/ContentComponent';
import LeftSidebarComponent from './components/Sidebar/LeftSidebarComponent';

const App: React.FC = () => {

  const [collapsed, setCollapsed] = useState(false);

  const handleToggleCollapse = (value: boolean) => {
    setCollapsed(value);
  };

 

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { getToken } = AuthUser();

  return (
    <div className="App">
      {getToken() &&
        <Layout>
          <LeftSidebarComponent collapsed={collapsed} onToggleCollapse={handleToggleCollapse}/>
          <ContentComponent collapsed={collapsed} onToggleCollapse={handleToggleCollapse}/>
        </Layout>
      }
      {!getToken() &&
        <>
          <Login />
        </>
      }

    </div>
  );
};

export default App;
