import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React from 'react';

const Contact: React.FC = () => (
    <>
        <CKEditor
            editor={ClassicEditor}
            data="<p>Hello from CKEditor&nbsp;5!</p>"
            onReady={editor => {
            }}
            onChange={(event, editor) => {
                const data = editor.getData();
            }}
            onBlur={(event, editor) => {
            }}
            onFocus={(event, editor) => {
            }}
        />
    </>
)

export default Contact;