import { InboxOutlined, PercentageOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import IFacing from "../../../../interfaces/IFacing";
import IProjectCategory from "../../../../interfaces/IProjectCategory";
import IProjectStatus from "../../../../interfaces/IProjectStatus";
import IProjectType from "../../../../interfaces/IProjectType";
import IRegion from "../../../../interfaces/IRegion";
import ISubRegion from "../../../../interfaces/ISubRegion";
import IUom from "../../../../interfaces/IUom";
import { API_URL } from "../../../../settings";
import { RcFile } from "antd/es/upload";
import moment from "moment";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

dayjs.extend(customParseFormat);

function Project() {
  const dateFormat = "YYYY-MM-DD";

  const navigate = useNavigate();
  var [formState, setFormState] = useState("CREATE");
  const [formSubmitButtonText, setFormSubmitButtonText] = useState("Create");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [projectTypes, setProjectTypes] = useState<IProjectType[]>([]);
  const [projectCategories, setProjectCategories] = useState<
    IProjectCategory[]
  >([]);
  const [projectStatuses, setProjectStatuses] = useState<IProjectStatus[]>([]);
  const [regions, setRegions] = useState<IRegion[]>([]);
  const [subRegions, setSubRegions] = useState<ISubRegion[]>([]);
  const [uoms, setUoms] = useState<IUom[]>([]);
  const [facings, setFacings] = useState<IFacing[]>([]);
  const [projectLayoutImageName, setProjectLayoutImageName] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [projectId, setProjectId] = useState<number>();
  const [projectCategoryId, setProjectCategoryId] = useState(1);
  const [projectLoading, setProjectLoading] = React.useState<boolean>(false);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [handoverTimeString, setHandoverTimeString] = useState("");

  const [projectForm] = Form.useForm();

  const { Dragger } = Upload;

  let { id } = useParams();

  useEffect(() => {
    if (formState === "CREATE") {
      setFormSubmitButtonText("Create");
      setIsFormDisabled(false);
      setProjectId(0);
    } else if (formState === "VIEW") {
      setFormSubmitButtonText("Change");
      setIsFormDisabled(true);
    } else {
      setFormSubmitButtonText("Change");
      setIsFormDisabled(false);
    }

    return () => {};
  }, [formState]);

  const handleRemoveItemSlider = async (file: UploadFile) => {
    setProjectLayoutImageName("");
    setFileList([]);
    return true;
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    return isJpgOrPng && isLt2M;
  };

  const props: UploadProps = {
    name: "file",
    // beforeUpload: beforeUpload,
    fileList: fileList,
    onPreview: handlePreview,
    action: `${API_URL}/image-upload`,
    onRemove: handleRemoveItemSlider,

    onChange(info) {
      setFileList(info.fileList);
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (
        info.file.percent === 100 &&
        status === "done" &&
        info.file.response &&
        info.file.response.file_name
      ) {
        setProjectLayoutImageName(info.file.response.file_name);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {},
  };

  useEffect(() => {
    onResetProjectForm();
    if (id) {
      getProject();
      setFormState("UPDATE");
    }
    getProjectTypeList();
    getProjectCategoryList();
    getProjectStatusList();
    getUomList();
    getFacingList();
    getRegionList();
    getSubRegionList();

    return () => {};
  }, []);

  const getProject = () => {
    setProjectLoading(true);
    axios
      .get(`${API_URL}/projects/${id}`)
      .then((response) => {
        setProjectCategoryId(response.data.project_category_id);
        setProjectLayoutImageName(response.data.project_layout_photo);
        if (response.data.project_layout_photo) {
          setFileList([
            {
              uid: response.data.id,
              name: response.data.project_layout_photo,
              status: "done",
              url:
                API_URL +
                "/image-download/" +
                response.data.project_layout_photo,
            },
          ]);
        }
        setHandoverTimeString(response.data.hand_over_time);
        projectForm.setFieldsValue({
          project_id: response.data.project_id,
          project_name: response.data.project_name,
          project_category_id: response.data.project_category_id,
          project_type_id: response.data.project_type_id,
          project_status_id: response.data.project_status_id,
          description: response.data.description,
          approval_code: response.data.approval_code,
          project_location: response.data.project_location,
          block_name_from: response.data.block_name_from,
          block_name_to: response.data.block_name_to,
          project_size: response.data.project_size,
          number_of_storied: response.data.number_of_storied,
          number_of_lift: response.data.number_of_lift,
          facing_id: response.data.facing_id,
          number_of_flat: response.data.number_of_flat,
          number_of_car_parking: response.data.number_of_car_parking,
          hand_over_time: dayjs(response.data.hand_over_time, dateFormat),
          open_space_pct: response.data.open_space_pct,
          road_size: response.data.road_size,
          number_of_plot: response.data.number_of_plot,
          project_layout_photo: response.data.project_layout_photo,
          region_id: response.data.region_id,
          sub_region_id: response.data.sub_region_id,
          approval_information: response.data.approval_information,
          general_number_of_plot: response.data.general_number_of_plot,
          commercial_number_of_plot: response.data.commercial_number_of_plot,
          duplex_number_of_plot: response.data.duplex_number_of_plot,
          publish_flag: response.data.publish_flag,
          uom_id: response.data.uom_id,
          mosque_flag: response.data.mosque_flag,
          hospital_flag: response.data.hospital_flag,
          market_flag: response.data.market_flag,
          convention_center_flag: response.data.convention_center_flag,
          edu_institute_flag: response.data.edu_institute_flag,
          fire_station_flag: response.data.fire_station_flag,
          substation_flag: response.data.substation_flag,
          pump_house_flag: response.data.pump_house_flag,
          project_security_office_flag:
            response.data.project_security_office_flag,
          power_division_aap_flag: response.data.power_division_aap_flag,
          municipality_aap_flag: response.data.municipality_aap_flag,
          wasa_aap_flag: response.data.wasa_aap_flag,
          submersible_pump_flag: response.data.submersible_pump_flag,
          gas_aap_flag: response.data.gas_aap_flag,
          fire_and_safety_aap_flag: response.data.fire_and_safety_aap_flag,
          dept_of_env_aap_flag: response.data.dept_of_env_aap_flag,
          civil_aviation_aap_flag: response.data.civil_aviation_aap_flag,
          police_station_aap_flag: response.data.police_station_aap_flag,
          traffic_control_auth_aap_flag:
            response.data.traffic_control_auth_aap_flag,
          word_comm_aap_flag: response.data.word_comm_aap_flag,
        });
        setProjectLoading(false);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
        setProjectLoading(false);
      });
  };

  const handleCancel = () => setPreviewOpen(false);

  const getProjectCategoryList = () => {
    // setTableSpinLoading(true);
    axios
      .get(`${API_URL}/project-categories`)
      .then((response) => {
        response.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
          x["value"] = x.id;
          x["label"] = x.name;
        });
        setProjectCategories(response.data);
        // setTableSpinLoading(false);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
        // setTableSpinLoading(false);
      });
  };

  const getRegionList = () => {
    // setTableSpinLoading(true);
    axios
      .get(`${API_URL}/regions`)
      .then((response) => {
        response.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
          x["value"] = x.id;
          x["label"] = x.name;
        });
        setRegions(response.data);
        // setTableSpinLoading(false);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
        // setTableSpinLoading(false);
      });
  };

  const getSubRegionList = () => {
    // setTableSpinLoading(true);
    axios
      .get(`${API_URL}/sub-regions`)
      .then((response) => {
        response.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
          x["value"] = x.id;
          x["label"] = x.name;
        });
        setSubRegions(response.data);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
      });
  };

  const getUomList = () => {
    // setTableSpinLoading(true);
    axios
      .get(`${API_URL}/uoms`)
      .then((response) => {
        response.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
          x["value"] = x.id;
          x["label"] = x.name;
        });
        setUoms(response.data);
        // setTableSpinLoading(false);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
      });
  };

  const getProjectTypeList = () => {
    // setTableSpinLoading(true);
    axios
      .get(`${API_URL}/project-types`)
      .then((response) => {
        response.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
          x["value"] = x.id;
          x["label"] = x.name;
        });
        setProjectTypes(response.data);
      })
      .catch((err) => {
        // Handle error
        console.log("server error", err);
      });
  };

  const getProjectStatusList = () => {
    // setTableSpinLoading(true);
    axios
      .get(`${API_URL}/project-statuses`)
      .then((response) => {
        response.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
          x["value"] = x.id;
          x["label"] = x.name;
        });
        setProjectStatuses(response.data);
        // setTableSpinLoading(false);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
        // setTableSpinLoading(false);
      });
  };

  const getFacingList = () => {
    // setTableSpinLoading(true);
    axios
      .get(`${API_URL}/facings`)
      .then((response) => {
        response.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
          x["value"] = x.id;
          x["label"] = x.name;
        });
        setFacings(response.data);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
      });
  };

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(date);
    console.log(dateString);
    setHandoverTimeString(dateString);
  };

  const onFinishProjectForm = (values: any) => {
    if (formState === "CREATE") {
      axios
        .post(`${API_URL}/projects`, {
          project_id: values.project_id,
          project_name: values.project_name,
          project_category_id: values.project_category_id,
          project_type_id: values.project_type_id,
          project_status_id: values.project_status_id,
          description: values.description,
          approval_code: values.approval_code,
          project_location: values.project_location,
          block_name_from: values.block_name_from,
          block_name_to: values.block_name_to,
          project_size: values.project_size,
          number_of_storied: values.number_of_storied,
          number_of_lift: values.number_of_lift,
          facing_id: values.facing_id,
          number_of_flat: values.number_of_flat,
          number_of_car_parking: values.number_of_car_parking,
          hand_over_time: handoverTimeString,
          open_space_pct: projectForm.getFieldValue("open_space_pct"),
          road_size: projectForm.getFieldValue("road_size"),
          number_of_plot: projectForm.getFieldValue("number_of_plot"),
          region_id: projectForm.getFieldValue("region_id"),
          sub_region_id: projectForm.getFieldValue("sub_region_id"),
          approval_information: projectForm.getFieldValue(
            "approval_information"
          ),
          general_number_of_plot: projectForm.getFieldValue(
            "general_number_of_plot"
          ),
          commercial_number_of_plot: projectForm.getFieldValue(
            "commercial_number_of_plot"
          ),
          duplex_number_of_plot: projectForm.getFieldValue(
            "duplex_number_of_plot"
          ),
          publish_flag: projectForm.getFieldValue("publish_flag"),
          uom_id: projectForm.getFieldValue("uom_id"),
          mosque_flag: projectForm.getFieldValue("mosque_flag"),
          hospital_flag: projectForm.getFieldValue("hospital_flag"),
          market_flag: projectForm.getFieldValue("market_flag"),
          convention_center_flag: projectForm.getFieldValue(
            "convention_center_flag"
          ),
          edu_institute_flag: projectForm.getFieldValue("edu_institute_flag"),
          fire_station_flag: projectForm.getFieldValue("fire_station_flag"),
          substation_flag: projectForm.getFieldValue("substation_flag"),
          pump_house_flag: projectForm.getFieldValue("pump_house_flag"),
          project_security_office_flag: projectForm.getFieldValue(
            "project_security_office_flag"
          ),
          power_division_aap_flag: projectForm.getFieldValue(
            "power_division_aap_flag"
          ),
          municipality_aap_flag: projectForm.getFieldValue(
            "municipality_aap_flag"
          ),
          wasa_aap_flag: projectForm.getFieldValue("wasa_aap_flag"),
          submersible_pump_flag: projectForm.getFieldValue(
            "submersible_pump_flag"
          ),
          gas_aap_flag: projectForm.getFieldValue("gas_aap_flag"),
          fire_and_safety_aap_flag: projectForm.getFieldValue(
            "fire_and_safety_aap_flag"
          ),
          dept_of_env_aap_flag: projectForm.getFieldValue(
            "dept_of_env_aap_flag"
          ),
          civil_aviation_aap_flag: projectForm.getFieldValue(
            "civil_aviation_aap_flag"
          ),
          police_station_aap_flag: projectForm.getFieldValue(
            "police_station_aap_flag"
          ),
          traffic_control_auth_aap_flag: projectForm.getFieldValue(
            "traffic_control_auth_aap_flag"
          ),
          word_comm_aap_flag: projectForm.getFieldValue("word_comm_aap_flag"),
          project_layout_photo: projectLayoutImageName,
        })
        .then((response) => {
          navigate("/projects");
        })
        .catch((err) => {
          console.log("server error", err);
        });
    } else if (formState === "UPDATE") {
      axios
        .put(`${API_URL}/projects/${id}`, {
          project_id: projectForm.getFieldValue("project_id"),
          project_name: projectForm.getFieldValue("project_name"),
          project_category_id: projectForm.getFieldValue("project_category_id"),
          project_type_id: projectForm.getFieldValue("project_type_id"),
          project_status_id: projectForm.getFieldValue("project_status_id"),
          description: projectForm.getFieldValue("description"),
          approval_code: projectForm.getFieldValue("approval_code"),
          project_location: projectForm.getFieldValue("project_location"),
          block_name_from: projectForm.getFieldValue("block_name_from"),
          block_name_to: projectForm.getFieldValue("block_name_to"),
          project_size: projectForm.getFieldValue("project_size"),
          number_of_storied: projectForm.getFieldValue("number_of_storied"),
          number_of_lift: projectForm.getFieldValue("number_of_lift"),
          facing_id: projectForm.getFieldValue("facing_id"),
          number_of_flat: projectForm.getFieldValue("number_of_flat"),
          number_of_car_parking: projectForm.getFieldValue(
            "number_of_car_parking"
          ),
          hand_over_time: handoverTimeString,
          open_space_pct: projectForm.getFieldValue("open_space_pct"),
          road_size: projectForm.getFieldValue("road_size"),
          number_of_plot: projectForm.getFieldValue("number_of_plot"),
          region_id: projectForm.getFieldValue("region_id"),
          sub_region_id: projectForm.getFieldValue("sub_region_id"),
          approval_information: projectForm.getFieldValue(
            "approval_information"
          ),
          general_number_of_plot: projectForm.getFieldValue(
            "general_number_of_plot"
          ),
          commercial_number_of_plot: projectForm.getFieldValue(
            "commercial_number_of_plot"
          ),
          duplex_number_of_plot: projectForm.getFieldValue(
            "duplex_number_of_plot"
          ),
          publish_flag: projectForm.getFieldValue("publish_flag"),
          uom_id: projectForm.getFieldValue("uom_id"),
          mosque_flag: projectForm.getFieldValue("mosque_flag"),
          hospital_flag: projectForm.getFieldValue("hospital_flag"),
          market_flag: projectForm.getFieldValue("market_flag"),
          convention_center_flag: projectForm.getFieldValue(
            "convention_center_flag"
          ),
          edu_institute_flag: projectForm.getFieldValue("edu_institute_flag"),
          fire_station_flag: projectForm.getFieldValue("fire_station_flag"),
          substation_flag: projectForm.getFieldValue("substation_flag"),
          pump_house_flag: projectForm.getFieldValue("pump_house_flag"),
          project_security_office_flag: projectForm.getFieldValue(
            "project_security_office_flag"
          ),
          power_division_aap_flag: projectForm.getFieldValue(
            "power_division_aap_flag"
          ),
          municipality_aap_flag: projectForm.getFieldValue(
            "municipality_aap_flag"
          ),
          wasa_aap_flag: projectForm.getFieldValue("wasa_aap_flag"),
          submersible_pump_flag: projectForm.getFieldValue(
            "submersible_pump_flag"
          ),
          gas_aap_flag: projectForm.getFieldValue("gas_aap_flag"),
          fire_and_safety_aap_flag: projectForm.getFieldValue(
            "fire_and_safety_aap_flag"
          ),
          dept_of_env_aap_flag: projectForm.getFieldValue(
            "dept_of_env_aap_flag"
          ),
          civil_aviation_aap_flag: projectForm.getFieldValue(
            "civil_aviation_aap_flag"
          ),
          police_station_aap_flag: projectForm.getFieldValue(
            "police_station_aap_flag"
          ),
          traffic_control_auth_aap_flag: projectForm.getFieldValue(
            "traffic_control_auth_aap_flag"
          ),
          word_comm_aap_flag: projectForm.getFieldValue("word_comm_aap_flag"),
          project_layout_photo: projectLayoutImageName,
        })
        .then((response) => {
          navigate("/projects");
        })
        .catch((err) => {
          console.log("server error", err);
        });
    }
  };

  const onResetProjectForm = () => {
    projectForm.resetFields();
  };

  const onChangeProjectCategory = () => {
    setProjectCategoryId(projectForm.getFieldValue("project_category_id"));
  };

  const blockRange = [
    { value: "A" },
    { value: "B" },
    { value: "C" },
    { value: "D" },
    { value: "E" },
    { value: "F" },
    { value: "G" },
    { value: "H" },
    { value: "I" },
    { value: "J" },
    { value: "K" },
    { value: "L" },
    { value: "M" },
    { value: "N" },
    { value: "O" },
    { value: "P" },
    { value: "Q" },
    { value: "R" },
    { value: "S" },
    { value: "T" },
    { value: "U" },
    { value: "V" },
    { value: "W" },
    { value: "X" },
    { value: "Y" },
    { value: "Z" },
  ];

  return (
    <>
      <Row gutter={10}>
        <Col lg={15} md={24}>
          <Card title="Project Information" bordered={true}>
            <Spin spinning={projectLoading}>
              <Form
                layout="vertical"
                name="realEstateConfigForm"
                form={projectForm}
                initialValues={{ remember: 1 }}
                autoComplete="off"
                onFinish={onFinishProjectForm}
              >
                <Row gutter={10}>
                  <Col md={12} lg={8}>
                    <Form.Item
                      name="project_id"
                      label="Project Code"
                      rules={[
                        {
                          required: true,
                          message: "Input something!",
                        },
                      ]}
                    >
                      <Input placeholder="Project Code" />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={8}>
                    <Form.Item
                      name="project_name"
                      label="Project Name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder="Project Name" />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={8} xs={24}>
                    <Form.Item
                      name="project_category_id"
                      label="Project Category"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Project Category"
                        onChange={onChangeProjectCategory}
                        options={projectCategories}
                      />
                    </Form.Item>
                  </Col>

                  <Col md={12} lg={8} xs={24}>
                    <Form.Item
                      name="project_type_id"
                      label="Project Type"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Project Type"
                        options={projectTypes}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={8} xs={24}>
                    <Form.Item
                      name="project_status_id"
                      label="Project Status"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Project Status"
                        options={projectStatuses}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={8}>
                    <Form.Item name="open_space_pct" label="Open Space">
                      <InputNumber
                        suffix={<PercentageOutlined />}
                        placeholder="Open Space"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={24} lg={12} >
                    <Form.Item
                      name="project_size"
                      label="Project Size"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Project Size"
                        addonAfter={
                          <Form.Item
                            name="uom_id"
                            noStyle
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              style={{ width: 100 }}
                              placeholder="UoM"
                              options={uoms}
                            ></Select>
                          </Form.Item>
                        }
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>

                  <Col md={12} lg={8} xs={24}>
                    <Form.Item
                      name="region_id"
                      label="Project Region"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select placeholder="Project Region" options={regions} />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={8} xs={24}>
                    <Form.Item name="sub_region_id" label="Project Sub-Region">
                      <Select
                        placeholder="Project Sub-Region"
                        options={subRegions}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      name="project_location"
                      label="Project Location"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder="Project Location" />
                    </Form.Item>
                  </Col>
                  {projectCategoryId === 1 && (
                    <>
                      <Col md={12} lg={8}>
                        <Form.Item
                          name="number_of_storied"
                          label="Number of Storied"
                        >
                          <InputNumber
                            placeholder="Number of Storied"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8}>
                        <Form.Item name="number_of_lift" label="Number of Lift">
                          <InputNumber
                            placeholder="Number of Lift"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item name="facing_id" label="Facing">
                          <Select placeholder="Facing" options={facings} />
                        </Form.Item>
                      </Col>

                      <Col md={12} lg={8}>
                        <Form.Item name="number_of_flat" label="Total Flat">
                          <InputNumber
                            placeholder="Total Flat"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8}>
                        <Form.Item
                          name="number_of_car_parking"
                          label="Car Parking"
                        >
                          <InputNumber
                            placeholder="placeholder"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item name="hand_over_time" label="Handover Time">
                          <DatePicker
                            format={dateFormat}
                            onChange={onChange}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}

                  {projectCategoryId === 2 && (
                    <>
                      <Col md={12} lg={8}>
                        <Form.Item name="number_of_plot" label="Total Plot">
                          <InputNumber
                            placeholder="Total Plot"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8}>
                        <Form.Item
                          name="block_name_from"
                          label="Block From"
                          initialValue={"A"}
                        >
                          <Select
                            placeholder="Block From"
                            options={blockRange}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8}>
                        <Form.Item
                          name="block_name_to"
                          label="Block To"
                          initialValue={"B"}
                        >
                          <Select placeholder="Block To" options={blockRange} />
                        </Form.Item>
                      </Col>

                      <Col md={12} lg={8}>
                        <Form.Item
                          name="general_number_of_plot"
                          label="General Plot No"
                        >
                          <InputNumber
                            placeholder="General Plot No"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={12} lg={8}>
                        <Form.Item
                          name="duplex_number_of_plot"
                          label="Duplex/Triplex Plot No"
                        >
                          <InputNumber
                            placeholder="Duplex/Triplex Plot No"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8}>
                        <Form.Item
                          name="commercial_number_of_plot"
                          label="Commercial Plot No"
                        >
                          <InputNumber
                            placeholder="Commercial Plot No"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="mosque_flag"
                          label="Mosque"
                          initialValue={0}
                        >
                          <Select
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 0, label: "No" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="hospital_flag"
                          label="Hospital"
                          initialValue={0}
                        >
                          <Select
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 0, label: "No" },
                            ]}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="market_flag"
                          label="Market"
                          initialValue={0}
                        >
                          <Select
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 0, label: "No" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="convention_center_flag"
                          label="Convention Center"
                          initialValue={0}
                        >
                          <Select
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 0, label: "No" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="edu_institute_flag"
                          label="Institute & Play Ground"
                          initialValue={0}
                        >
                          <Select
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 0, label: "No" },
                            ]}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="fire_station_flag"
                          label="Fire Station"
                          initialValue={0}
                        >
                          <Select
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 0, label: "No" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="substation_flag"
                          label="Sub Station"
                          initialValue={0}
                        >
                          <Select
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 0, label: "No" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="pump_house_flag"
                          label="Pump House"
                          initialValue={0}
                        >
                          <Select
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 0, label: "No" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                  <Col md={12} lg={8} xs={24}>
                    <Form.Item
                      name="power_division_aap_flag"
                      label="Power Division"
                      initialValue={0}
                    >
                      <Select
                        options={[
                          { value: 1, label: "Yes" },
                          { value: 0, label: "No" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={8} xs={24}>
                    <Form.Item
                      name="fire_and_safety_aap_flag"
                      label="Fire & Safety"
                      initialValue={0}
                    >
                      <Select
                        options={[
                          { value: 1, label: "Yes" },
                          { value: 0, label: "No" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={8} xs={24}>
                    <Form.Item
                      name="traffic_control_auth_aap_flag"
                      label="Traffic Control Authority"
                      initialValue={0}
                    >
                      <Select
                        options={[
                          { value: 1, label: "Yes" },
                          { value: 0, label: "No" },
                        ]}
                      />
                    </Form.Item>
                  </Col>

                  <Col md={12} lg={8} xs={24}>
                    <Form.Item
                      name="municipality_aap_flag"
                      label="City Corporation"
                      initialValue={0}
                    >
                      <Select
                        options={[
                          { value: 1, label: "Yes" },
                          { value: 0, label: "No" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={8} xs={24}>
                    <Form.Item
                      name="word_comm_aap_flag"
                      label="Union Council"
                      initialValue={0}
                    >
                      <Select
                        options={[
                          { value: 1, label: "Yes" },
                          { value: 0, label: "No" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={8} xs={24}>
                    <Form.Item
                      name="police_station_aap_flag"
                      label="Police Station"
                      initialValue={0}
                    >
                      <Select
                        options={[
                          { value: 1, label: "Yes" },
                          { value: 0, label: "No" },
                        ]}
                      />
                    </Form.Item>
                  </Col>

                  <Col md={12} lg={8} xs={24}>
                    <Form.Item
                      name="wasa_aap_flag"
                      label="WASA"
                      initialValue={0}
                    >
                      <Select
                        options={[
                          { value: 1, label: "Yes" },
                          { value: 0, label: "No" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={8} xs={24}>
                    <Form.Item
                      name="submersible_pump_flag"
                      label="Submersible Pump"
                      initialValue={0}
                    >
                      <Select
                        options={[
                          { value: 1, label: "Yes" },
                          { value: 0, label: "No" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={8} xs={24}>
                    <Form.Item
                      name="civil_aviation_aap_flag"
                      label="Civil Aviation"
                      initialValue={0}
                    >
                      <Select
                        options={[
                          { value: "On Process", label: "On Process" },
                          { value: 1, label: "Yes" },
                          { value: 0, label: "No" },
                        ]}
                      />
                    </Form.Item>
                  </Col> 
                  <Col md={12} lg={8} xs={24}>
                    <Form.Item
                      name="dept_of_env_aap_flag"
                      label="Department of Environment"
                      initialValue={0}
                    >
                      <Select
                        options={[
                          { value: 1, label: "Yes" },
                          { value: 0, label: "No" },
                        ]}
                      />
                    </Form.Item>
                  </Col>

                  <Col md={12} lg={8} xs={24}>
                    <Form.Item
                      name="gas_aap_flag"
                      label="Gas&Transmission Authority"
                      initialValue={0}
                    >
                      <Select
                        placeholder="Gas & Transmission Authority"
                        options={[
                          { value: 1, label: "Yes" },
                          { value: 0, label: "No" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={8} xs={24}>
                    <Form.Item
                      name="project_security_office_flag"
                      label="Security Office"
                      initialValue={0}
                    >
                      <Select
                        placeholder="Security Office"
                        options={[
                          { value: 1, label: "Yes" },
                          { value: 0, label: "No" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={8} xs={24}>
                    <Form.Item
                      name="publish_flag"
                      label="Publish Status"
                      required
                      initialValue={0}
                    >
                      <Select
                        options={[
                          { value: 0, label: "Draft" },
                          { value: 1, label: "Publish" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item>
                      <Space>
                        <Button type="primary" htmlType="submit">
                          {formSubmitButtonText}
                        </Button>
                        <Button htmlType="button" onClick={onResetProjectForm}>
                          Reset
                        </Button>
                        <Link to={"/projects"}> Cancel</Link>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Card>
        </Col>
        <Col lg={9} md={24}>
          <Row>
            <Col span={24}>
              <Card title="Project Layout" bordered={true}>
                <div>
                  <Spin spinning={projectLoading}>
                    <Dragger
                      maxCount={1}
                      listType="picture-card"
                      {...props}
                      accept={"image/*"}
                      multiple={false}
                      showUploadList={{
                        showPreviewIcon: true,
                        showRemoveIcon: true,
                        showDownloadIcon: true,
                      }}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Support for a single image upload. Strictly prohibited
                        from uploading company data or other banned files.
                      </p>
                    </Dragger>
                    <Modal
                      open={previewOpen}
                      footer={null}
                      onCancel={handleCancel}
                    >
                      <img
                        alt="slider"
                        style={{ width: "100%" }}
                        src={previewImage}
                      />
                    </Modal>
                  </Spin>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Project;
