import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Upload,
  UploadFile,
  message,
} from "antd";
import { RcFile, UploadProps } from "antd/es/upload";
import axios from "axios";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import IFacing from "../../../../interfaces/IFacing";
import IItemSlider from "../../../../interfaces/IItemSlider";
import IPlotPosition from "../../../../interfaces/IPlotPosition";
import IProject from "../../../../interfaces/IProject";
import IProjectCategory from "../../../../interfaces/IProjectCategory";
import IProjectStatus from "../../../../interfaces/IProjectStatus";
import IProjectType from "../../../../interfaces/IProjectType";
import IUom from "../../../../interfaces/IUom";
import { API_URL } from "../../../../settings";
import YouTube, { YouTubeProps } from "react-youtube";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

function Item() {
  const [projects, setProjects] = useState<IProject[]>([]);

  const navigate = useNavigate();
  var [formState, setFormState] = useState("CREATE");
  const [formSubmitButtonText, setFormSubmitButtonText] = useState("Create");
  const [itemLoading, setItemLoading] = React.useState<boolean>(false);
  const [sliderLoading, setSliderLoading] = React.useState<boolean>(false);
  const [projectTypes, setProjectTypes] = useState<IProjectType[]>([]);
  const [projectCategories, setProjectCategories] = useState<
    IProjectCategory[]
  >([]);
  const [plotPositions, setPlotPositions] = useState<IPlotPosition[]>([]);
  const [itemDetails, setItemDetails] = useState<string>();
  const [itemDetailsNL, setProjectDetailsNL] = useState<string>();
  const [projectTermsCondition, setProjectTermsCondition] = useState<string>();
  const [itemTermsConditionNL, setItemTermsConditionNL] = useState<string>();

  const [projectStatuses, setProjectStatuses] = useState<IProjectStatus[]>([]);
  const [uoms, setUoms] = useState<IUom[]>([]);
  const [itemBrandImageName, setItemBrandImageName] = useState("");
  const [youtubeId, setYoutubeId] = useState();
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [projectId, setProjectId] = useState<number>();
  const [projectCategoryId, setProjectCategoryId] = useState(1);
  const [facings, setFacings] = useState<IFacing[]>([]);
  const [itemSliders, setItemSliders] = useState<IItemSlider[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [branItemfileList, setBranItemFileList] = useState<UploadFile[]>([]);

  const [projectForm] = Form.useForm();

  const { Dragger } = Upload;

  const blockRange = [
    { value: "A" },
    { value: "B" },
    { value: "C" },
    { value: "D" },
    { value: "E" },
    { value: "F" },
    { value: "G" },
    { value: "H" },
    { value: "I" },
    { value: "J" },
    { value: "K" },
    { value: "L" },
    { value: "M" },
    { value: "N" },
    { value: "O" },
    { value: "P" },
    { value: "Q" },
    { value: "R" },
    { value: "S" },
    { value: "T" },
    { value: "U" },
    { value: "V" },
    { value: "W" },
    { value: "X" },
    { value: "Y" },
    { value: "Z" },
  ];

  const getPlotPositionList = () => {
    axios
      .get(`${API_URL}/plot-positions`)
      .then((response) => {
        response.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
          x["value"] = x.id;
          x["label"] = x.name;
        });
        setPlotPositions(response.data);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
      });
  };

  const onFinishItemForm = (values: any) => {
    if (!id) {
      axios
        .post(`${API_URL}/items`, {
          item_id: projectForm.getFieldValue("item_id"),
          name: projectForm.getFieldValue("name"),
          description: itemDetails,
          description_natural_language: itemDetailsNL,
          project_id: projectForm.getFieldValue("project_id"),
          road_size: projectForm.getFieldValue("road_size"),
          facing_id: projectForm.getFieldValue("facing_id"),
          block_name: projectForm.getFieldValue("block_name"),
          plot_size: projectForm.getFieldValue("plot_size"),
          price: projectForm.getFieldValue("price"),
          total_price: projectForm.getFieldValue("total_price"),
          flat_size: projectForm.getFieldValue("flat_size"),
          flat_place_of_storied: projectForm.getFieldValue(
            "flat_place_of_storied"
          ),
          flat_number: projectForm.getFieldValue("flat_number"),
          item_brand_photo: itemBrandImageName,
          number_of_lift: projectForm.getFieldValue("number_of_lift"),
          decoration_condition: projectForm.getFieldValue(
            "decoration_condition"
          ),
          number_of_bedroom: projectForm.getFieldValue("number_of_bedroom"),
          number_of_living_and_dining: projectForm.getFieldValue(
            "number_of_living_and_dining"
          ),
          number_of_kitchen: projectForm.getFieldValue("number_of_kitchen"),
          number_of_toilet: projectForm.getFieldValue("number_of_toilet"),
          number_of_swimming_pool: projectForm.getFieldValue(
            "number_of_swimming_pool"
          ),
          number_of_gym: projectForm.getFieldValue("number_of_gym"),
          parking_area_flag: projectForm.getFieldValue("parking_area_flag"),
          parking_price: projectForm.getFieldValue("parking_price"),
          item_inventory_flag: projectForm.getFieldValue("item_inventory_flag"),
          number_of_balcony: projectForm.getFieldValue("number_of_balcony"),
          inactive_flag: projectForm.getFieldValue("inactive_flag"),
          security_flag: projectForm.getFieldValue("security_flag"),
          cctv_flag: projectForm.getFieldValue("cctv_flag"),
          conference_hall_flag: projectForm.getFieldValue(
            "conference_hall_flag"
          ),
          heating_flag: projectForm.getFieldValue("heating_flag"),
          cooling_flag: projectForm.getFieldValue("cooling_flag"),
          built_year: projectForm.getFieldValue("built_year"),
          discount_amount: projectForm.getFieldValue("discount_amount"),
          internet_flag: projectForm.getFieldValue("internet_flag"),
          discount_pct: projectForm.getFieldValue("discount_pct"),
          net_price: projectForm.getFieldValue("net_price"),
          terms_condition: projectTermsCondition,
          terms_condition_natural_language: itemTermsConditionNL,
          number_of_plot: projectForm.getFieldValue("number_of_plot"),
          publish_flag: projectForm.getFieldValue("publish_flag"),
          uom_id: projectForm.getFieldValue("uom_id"),
          item_size: projectForm.getFieldValue("item_size"),
          road_side: projectForm.getFieldValue("road_side"),
          plot_position_id: projectForm.getFieldValue("plot_position_id"),
          booking_money: projectForm.getFieldValue("booking_money"),
          price_negotiable_flag: projectForm.getFieldValue(
            "price_negotiable_flag"
          ),
          youtube_id: projectForm.getFieldValue("youtube_id"),
          item_sliders: itemSliders,
          // others: projectForm.getFieldValue('others'),
        })
        .then((response) => {
          navigate("/items");
        })
        .catch((err) => {
          console.log("server error", err);
        });
    } else {
      axios
        .put(`${API_URL}/items/${id}`, {
          item_id: projectForm.getFieldValue("item_id"),
          name: projectForm.getFieldValue("name"),
          description: itemDetails,
          description_natural_language: itemDetailsNL,
          project_id: projectForm.getFieldValue("project_id"),
          road_size: projectForm.getFieldValue("road_size"),
          facing_id: projectForm.getFieldValue("facing_id"),
          block_name: projectForm.getFieldValue("block_name"),
          plot_size: projectForm.getFieldValue("plot_size"),
          price: projectForm.getFieldValue("price"),
          total_price: projectForm.getFieldValue("total_price"),
          flat_size: projectForm.getFieldValue("flat_size"),
          item_brand_photo: itemBrandImageName,
          flat_place_of_storied: projectForm.getFieldValue(
            "flat_place_of_storied"
          ),
          flat_number: projectForm.getFieldValue("flat_number"),
          number_of_lift: projectForm.getFieldValue("number_of_lift"),
          decoration_condition: projectForm.getFieldValue(
            "decoration_condition"
          ),
          number_of_bedroom: projectForm.getFieldValue("number_of_bedroom"),
          number_of_living_and_dining: projectForm.getFieldValue(
            "number_of_living_and_dining"
          ),
          number_of_kitchen: projectForm.getFieldValue("number_of_kitchen"),
          number_of_toilet: projectForm.getFieldValue("number_of_toilet"),
          number_of_swimming_pool: projectForm.getFieldValue(
            "number_of_swimming_pool"
          ),
          number_of_gym: projectForm.getFieldValue("number_of_gym"),
          parking_area_flag: projectForm.getFieldValue("parking_area_flag"),
          parking_price: projectForm.getFieldValue("parking_price"),
          item_inventory_flag: projectForm.getFieldValue("item_inventory_flag"),
          number_of_balcony: projectForm.getFieldValue("number_of_balcony"),
          inactive_flag: projectForm.getFieldValue("inactive_flag"),
          security_flag: projectForm.getFieldValue("security_flag"),
          cctv_flag: projectForm.getFieldValue("cctv_flag"),
          conference_hall_flag: projectForm.getFieldValue(
            "conference_hall_flag"
          ),
          heating_flag: projectForm.getFieldValue("heating_flag"),
          cooling_flag: projectForm.getFieldValue("cooling_flag"),
          built_year: projectForm.getFieldValue("built_year"),
          discount_amount: projectForm.getFieldValue("discount_amount"),
          internet_flag: projectForm.getFieldValue("internet_flag"),
          discount_pct: projectForm.getFieldValue("discount_pct"),
          net_price: projectForm.getFieldValue("net_price"),
          terms_condition: projectTermsCondition,
          terms_condition_natural_language: itemTermsConditionNL,
          number_of_plot: projectForm.getFieldValue("number_of_plot"),
          publish_flag: projectForm.getFieldValue("publish_flag"),
          uom_id: projectForm.getFieldValue("uom_id"),
          item_size: projectForm.getFieldValue("item_size"),
          road_side: projectForm.getFieldValue("road_side"),
          plot_position_id: projectForm.getFieldValue("plot_position_id"),
          booking_money: projectForm.getFieldValue("booking_money"),
          price_negotiable_flag: projectForm.getFieldValue(
            "price_negotiable_flag"
          ),
          youtube_id: projectForm.getFieldValue("youtube_id"),
          // others: projectForm.getFieldValue('others'),
          item_sliders: itemSliders,
        })
        .then((response) => {
          navigate("/items");
        })
        .catch((err) => {
          console.log("server error", err);
        });
    }
  };

  const getProjectCategoryList = () => {
    // setTableSpinLoading(true);
    axios
      .get(`${API_URL}/project-categories`)
      .then((response) => {
        response.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
          x["value"] = x.id;
          x["label"] = x.name;
        });
        setProjectCategories(response.data);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
      });
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const onChangeProject = () => {
    getProject(projectForm.getFieldValue("project_id"));
  };

  const getProject = (projectId: string) => {
    if (projectId) {
      axios
        .get(`${API_URL}/projects/${projectId}`)
        .then((response) => {
          setProjectCategoryId(response.data.project_category_id);
          projectForm.setFieldValue(
            "project_type_id",
            response.data.project_type_id
          );
          projectForm.setFieldValue(
            "project_status_id",
            response.data.project_status_id
          );
          projectForm.setFieldValue("project_size", response.data.project_size);
          projectForm.setFieldValue("project_uom_id", response.data.uom_id);
          projectForm.setFieldValue(
            "project_category_id",
            response.data.project_category_id
          );
        })
        .catch((err) => {
          // Handle error
          console.log("server error");
        });
    }
  };

  const handleRemoveItemSlider = async (file: UploadFile) => {
    setItemSliders(itemSliders.filter((x) => x.image_name !== file.name));
    return true;
  };

  const opts: YouTubeProps["opts"] = {
    height: "auto",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  const handleRemoveBrandItemSlider = async (file: UploadFile) => {
    setItemBrandImageName("");
    setBranItemFileList([]);
    return true;
  };

  const handleChange: UploadProps["onChange"] = (info) => {
    setFileList(info.fileList);
    const { status } = info.file;

    if (
      info.file.percent === 100 &&
      status === "done" &&
      info.file.response &&
      info.file.response.file_name
    ) {
      const itemSliderObject = {
        image_name: info.file.response.file_name,
        item_id: id ? parseInt(id) : undefined,
      };
      setItemSliders((prevState) => [...prevState, itemSliderObject]);
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  let { id } = useParams();

  const onResetItemForm = () => {
    projectForm.resetFields();
  };

  useEffect(() => {
    onResetItemForm();
    if (id) {
      getItem();
      setFormSubmitButtonText("Change");
    }
    getProjectList();
    getProjectTypeList();
    getProjectStatusList();
    getFacingList();
    getUomList();
    getPlotPositionList();
    getProjectCategoryList();
    return () => {};
  }, []);

  const getUomList = () => {
    // setTableSpinLoading(true);
    axios
      .get(`${API_URL}/uoms`)
      .then((response) => {
        response.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
          x["value"] = x.id;
          x["label"] = x.name;
        });
        setUoms(response.data);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
        // setTableSpinLoading(false);
      });
  };

  const getProjectStatusList = () => {
    // setTableSpinLoading(true);
    axios
      .get(`${API_URL}/project-statuses`)
      .then((response) => {
        response.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
          x["value"] = x.id;
          x["label"] = x.name;
        });
        setProjectStatuses(response.data);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
      });
  };
  const itemThumnailprops: UploadProps = {
    name: "file",
    // beforeUpload: beforeUpload,
    fileList: branItemfileList,
    // onPreview: handlePreview,
    action: `${API_URL}/image-upload`,
    onRemove: handleRemoveBrandItemSlider,

    onChange(info) {
      setBranItemFileList(info.fileList);
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (
        info.file.percent === 100 &&
        status === "done" &&
        info.file.response &&
        info.file.response.file_name
      ) {
        setItemBrandImageName(info.file.response.file_name);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {},
  };

  const getItem = () => {
    setItemLoading(true);
    axios
      .get(`${API_URL}/items/${id}`)
      .then((response) => {
        getItemSliderList();
        setItemBrandImageName(response.data.item_brand_photo);
        if (response.data.item_brand_photo) {
          setBranItemFileList([
            {
              uid: response.data.id,
              name: response.data.item_brand_photo,
              status: "done",
              url:
                API_URL + "/image-download/" + response.data.item_brand_photo,
            },
          ]);
        }
        getProject(response.data.project_id);
        setItemDetails(response.data.description);
        setProjectDetailsNL(response.data.description_natural_language);
        setProjectTermsCondition(response.data.terms_condition);
        setItemTermsConditionNL(response.data.terms_condition_natural_language);
        projectForm.setFieldsValue({
          item_id: response.data.item_id,
          name: response.data.name,
          project_id: response.data.project_id,
          road_size: response.data.road_size,
          facing_id: response.data.facing_id,
          block_name: response.data.block_name,
          plot_size: response.data.plot_size,
          price: response.data.price,
          total_price: response.data.total_price,
          flat_size: response.data.flat_size,
          flat_place_of_storied: response.data.flat_place_of_storied,
          flat_number: response.data.flat_number,
          number_of_lift: response.data.number_of_lift,
          decoration_condition: response.data.decoration_condition,
          number_of_bedroom: response.data.number_of_bedroom,
          number_of_living_and_dining:
            response.data.number_of_living_and_dining,
          number_of_kitchen: response.data.number_of_kitchen,
          number_of_toilet: response.data.number_of_toilet,
          number_of_swimming_pool: response.data.number_of_swimming_pool,
          number_of_gym: response.data.number_of_gym,
          parking_area_flag: response.data.parking_area_flag,
          parking_price: response.data.parking_price,
          item_inventory_flag: response.data.item_inventory_flag,
          number_of_balcony: response.data.number_of_balcony,
          inactive_flag: response.data.inactive_flag,
          security_flag: response.data.security_flag,
          cctv_flag: response.data.cctv_flag,
          conference_hall_flag: response.data.conference_hall_flag,
          heating_flag: response.data.heating_flag,
          cooling_flag: response.data.cooling_flag,
          built_year: response.data.built_year,
          discount_amount: response.data.discount_amount,
          internet_flag: response.data.internet_flag,
          discount_pct: response.data.discount_pct,
          net_price: response.data.net_price,
          // terms_condition: response.data.terms_condition,
          // terms_condition_natural_language: response.data.terms_condition_natural_language,
          number_of_plot: response.data.number_of_plot,
          publish_flag: response.data.publish_flag,
          uom_id: response.data.uom_id,
          item_size: response.data.item_size,
          road_side: response.data.road_side,
          plot_position_id: response.data.plot_position_id,
          booking_money: response.data.booking_money,
          price_negotiable_flag: response.data.price_negotiable_flag,
          youtube_id: response.data.youtube_id,
        });
        setYoutubeId(response.data.youtube_id);
        setItemLoading(false);
      })
      .catch((err) => {
        setItemLoading(false);
        console.log("server error", err);
      });
  };

  const getFacingList = () => {
    // setTableSpinLoading(true);
    axios
      .get(`${API_URL}/facings`)
      .then((response) => {
        response.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
          x["value"] = x.id;
          x["label"] = x.name;
        });
        setFacings(response.data);
        // setTableSpinLoading(false);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
      });
  };

  const getItemSliderList = () => {
    setSliderLoading(true);
    axios
      .get(`${API_URL}/item-sliders/items/${id}`)
      .then((response) => {
        response.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
          x["uid"] = x.id;
          x["name"] = x.image_name;
          x["status"] = "done";
          x["url"] = API_URL + "/image-download/" + x.image_name;
        });

        setItemSliders(response.data);
        setFileList(response.data);
        setSliderLoading(false);
      })
      .catch((err) => {
        setSliderLoading(false);
        console.log("server error", err);
      });
  };

  const getProjectTypeList = () => {
    // setTableSpinLoading(true);
    axios
      .get(`${API_URL}/project-types`)
      .then((response) => {
        response.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
          x["value"] = x.id;
          x["label"] = x.name;
        });
        setProjectTypes(response.data);
      })
      .catch((err) => {
        // Handle error
        console.log("server error", err);
      });
  };

  const getProjectList = () => {
    axios
      .get(`${API_URL}/projects`)
      .then((response) => {
        response.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
          x["value"] = x.id;
          x["label"] = "[" + x.project_id + "] " + x.project_name;
        });
        setProjects(response.data);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
      });
  };

  return (
    <>
      <Form
        layout="vertical"
        name="realEstateConfigForm"
        form={projectForm}
        initialValues={{ remember: 1 }}
        autoComplete="off"
        onFinish={onFinishItemForm}
      >
        <Row gutter={10}>
          <Col lg={15} md={24}>
            <Card title="Item Information" bordered={true}>
              <Spin spinning={itemLoading}>
                <Row gutter={10}>
                  <Col md={12} lg={8} xs={24}>
                    <Form.Item
                      name="item_id"
                      label="Item Id"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder="Item Id" />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={8} xs={24}>
                    <Form.Item
                      name="name"
                      label="Item Name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder="Item Name" />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={8} xs={24}>
                    <Form.Item
                      name="project_id"
                      label="Project"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        disabled={id ? true : false}
                        placeholder="Project"
                        options={projects}
                        onChange={onChangeProject}
                      />
                    </Form.Item>
                  </Col>

                  <Col md={12} lg={8} xs={24}>
                    <Form.Item
                      name="project_category_id"
                      label="Project Category"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        disabled
                        placeholder="Project Category"
                        options={projectCategories}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={8} xs={24}>
                    <Form.Item
                      name="project_type_id"
                      label="Project Type"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        disabled
                        placeholder="Project Type"
                        options={projectTypes}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={8} xs={24}>
                    <Form.Item
                      name="project_status_id"
                      label="Project Status"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        disabled
                        placeholder="Project Status"
                        options={projectStatuses}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      name="project_size"
                      label="Project Size"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber
                        readOnly={true}
                        placeholder="Project Size"
                        addonAfter={
                          <Form.Item
                            name="project_uom_id"
                            noStyle
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              disabled
                              style={{ width: 100 }}
                              placeholder="UoM"
                              options={uoms}
                            ></Select>
                          </Form.Item>
                        }
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>

                  <Col md={12} lg={8} xs={24}> 
                    <Form.Item
                      name="decoration_condition"
                      label="Decoration Condition"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      initialValue={"WELL_FURNISHED"}
                    >
                      <Select
                        placeholder="Decoration Condition"
                        options={[
                          { value: "WELL_FURNISHED", label: "Well furnished" },
                          {
                            value: "NON_WELL_FURNISHED",
                            label: "Non furnished",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={24} lg={12} xs={24}>
                    <Form.Item
                      name="item_size"
                      label="Item Size"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Item Size"
                        addonAfter={
                          <Form.Item
                            name="uom_id"
                            noStyle
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              style={{ width: 100 }}
                              placeholder="UoM"
                              options={uoms}
                            ></Select>
                          </Form.Item>
                        }
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={8} xs={24}>
                    <Form.Item
                      name="facing_id"
                      label="Facing"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select placeholder="Facing" options={facings} />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item name="description" label="Description">
                      <CKEditor
                        editor={ClassicEditor}
                        data={itemDetails ? itemDetails : ""}
                        onChange={(event, editor) => {
                          setItemDetails(editor.getData());
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="description_natural_language"
                      label="Description (Bangla)"
                    >
                      <CKEditor
                        editor={ClassicEditor}
                        data={itemDetailsNL ? itemDetailsNL : ""}
                        onChange={(event, editor) => {
                          setProjectDetailsNL(editor.getData());
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      name="terms_condition"
                      label="Terms & Conditions"
                    >
                      <CKEditor
                        editor={ClassicEditor}
                        data={
                          projectTermsCondition ? projectTermsCondition : ""
                        }
                        onChange={(event, editor) => {
                          setProjectTermsCondition(editor.getData());
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="terms_condition_natural_language"
                      label="Terms & Conditions (Bangla)"
                    >
                      <CKEditor
                        editor={ClassicEditor}
                        data={itemTermsConditionNL ? itemTermsConditionNL : ""}
                        onChange={(event, editor) => {
                          setItemTermsConditionNL(editor.getData());
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {projectCategoryId === 1 && (
                    <>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="flat_place_of_storied"
                          label="Number of Storied"
                        >
                          <InputNumber
                            placeholder="Number of Storied"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="number_of_lift"
                          label="Number of Lifts"
                        >
                          <InputNumber
                            placeholder="Number of Lift"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item name="built_year" label="Year Of Build">
                          <InputNumber
                            placeholder="Year Of Build"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="number_of_bedroom"
                          label="Number of Bedrooms"
                        >
                          <InputNumber
                            placeholder="Number of Storied"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="number_of_living_and_dining"
                          label="Living And Dining"
                        >
                          <InputNumber
                            placeholder="Number of Lift"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="number_of_kitchen"
                          label="Number of Kitchens"
                        >
                          <InputNumber
                            placeholder="Number of Kitchens"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="number_of_balcony"
                          label="Number of Balconies"
                        >
                          <InputNumber
                            placeholder="Number of Balconies"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="number_of_toilet"
                          label="Number of Toilets"
                        >
                          <InputNumber
                            placeholder="Number of Toilets"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="number_of_swimming_pool"
                          label="Number of Swimming Pools"
                        >
                          <InputNumber
                            placeholder="Number of Swimming Pools"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item name="number_of_gym" label="Number of Gym">
                          <InputNumber
                            placeholder="Number of Gym"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="parking_are"
                          label="Parking Area"
                          initialValue={1}
                        >
                          <Select
                            placeholder="Parking Area"
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 0, label: "No" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item name="parking_price" label="Parking Price">
                          <InputNumber
                            placeholder="Parking Price"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="security_flag"
                          label="Security System"
                          initialValue={1}
                        >
                          <Select
                            placeholder="Security System"
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 0, label: "No" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="cctv_flag"
                          label="CCTV Control"
                          initialValue={1}
                        >
                          <Select
                            placeholder="CCTV Control"
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 0, label: "No" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="conference_hall_flag"
                          label="Conference Hall"
                          initialValue={0}
                        >
                          <Select
                            placeholder="Conference Hall"
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 0, label: "No" },
                            ]}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="heating_flag"
                          label="Central Heating"
                          initialValue={1}
                        >
                          <Select
                            placeholder="Central Heating"
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 0, label: "No" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="cooling_flag"
                          label="Central Cooling"
                          initialValue={1}
                        >
                          <Select
                            placeholder="Central Cooling"
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 0, label: "No" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="internet_flag"
                          label="Internet Facilities"
                        >
                          <Select
                            placeholder="Internet Facilities"
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 0, label: "No" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="cable_tv_flag"
                          label="TV Cable"
                          initialValue={1}
                        >
                          <Select
                            placeholder="TV Cable"
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 0, label: "No" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                  {projectCategoryId === 2 && (
                    <>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item name="number_of_plot" label="Plot No">
                          <InputNumber style={{ width: "100%" }}></InputNumber>
                        </Form.Item>
                      </Col>

                      <Col md={12} lg={8} xs={24}>
                        <Form.Item name="road_side" label="Road Side">
                          <Select
                            placeholder="Road Size"
                            options={[
                              { value: 1, label: "One Side Road" },
                              { value: 2, label: "Two Side Road" },
                              { value: 3, label: "Three Side Road" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="road_size"
                          label="Road Size"
                          initialValue={1}
                        >
                          <Input></Input>
                        </Form.Item>
                      </Col>

                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="plot_position_id"
                          label="Plot Position"
                        >
                          <Select
                            placeholder="Plot Position"
                            options={plotPositions}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={8} xs={24}>
                        <Form.Item
                          name="block_name"
                          label="Block"
                          initialValue={"A"}
                        >
                          <Select placeholder="Block" options={blockRange} />
                        </Form.Item>
                      </Col>
                    </>
                  )}

                  <Col md={12} lg={8} xs={24}>
                    <Form.Item name="price" label="Price">
                      <InputNumber
                        placeholder="Price"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>

                  <Col md={12} lg={8} xs={24}>
                    <Form.Item name="booking_money" label="Booking Money">
                      <InputNumber
                        placeholder="Booking Money"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={8} xs={24}>
                    <Form.Item name="discount_amount" label="Discount Amount">
                      <InputNumber
                        placeholder="Discount Amount"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={8} xs={24}>
                    <Form.Item name="net_price" label="Net Price">
                      <InputNumber
                        placeholder="Net Price"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={8} xs={24}>
                    <Form.Item
                      name="publish_flag"
                      label="Publish Status"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      initialValue={0}
                    >
                      <Select
                        options={[
                          { value: 0, label: "Draft" },
                          { value: 1, label: "Publish" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <Form.Item>
                      <Space >
                        <Button type="primary" htmlType="submit">
                          {formSubmitButtonText}
                        </Button>
                        {!id && (
                          <Button htmlType="button" onClick={onResetItemForm}>
                            Reset
                          </Button>
                        )}
                        <Link className="text-decoration-none" to={"/items"}>
                          Cancel
                        </Link>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Spin>
            </Card>
          </Col>
          <Col lg={9} md={24}>
            <Row className="mb-2">
              <Col span={24}>
                <Card title="Thumbnail" bordered={true}>
                  <Dragger
                    maxCount={1}
                    listType="picture-card"
                    {...itemThumnailprops}
                    accept={"image/*"}
                    multiple={false}
                    showUploadList={{
                      showPreviewIcon: true,
                      showRemoveIcon: true,
                      showDownloadIcon: true,
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Support for a single image upload. Strictly prohibited
                      from uploading company data or other banned files.
                    </p>
                  </Dragger>
                  <Modal
                    open={previewOpen}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <img
                      alt="slider"
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  </Modal>
                </Card>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col span={24}>
                <Card title="Sliders" bordered={true}>
                  <Spin spinning={sliderLoading}>
                    <div>
                      <Upload
                        action={`${API_URL}/image-upload`}
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onRemove={handleRemoveItemSlider}
                        onChange={handleChange}
                        accept={"image/*"}
                        maxCount={8}
                        multiple={true}
                        showUploadList={{
                          showPreviewIcon: true,
                          showRemoveIcon: true,
                          showDownloadIcon: true,
                        }}
                      >
                        {fileList.length >= 8 ? null : uploadButton}
                      </Upload>
                      <Modal
                        open={previewOpen}
                        footer={null}
                        onCancel={handleCancel}
                      >
                        <img
                          alt="slider"
                          style={{ width: "100%" }}
                          src={previewImage}
                        />
                      </Modal>
                    </div>
                  </Spin>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Card title="Youtube Video" bordered={true}>
                  <div>
                    {youtubeId && (
                      <YouTube
                        videoId={youtubeId} // defaults -> ''
                        opts={opts}
                      />
                    )}
                  </div>
                  <Form.Item name={"youtube_id"} label="Youtube Id">
                    <Input placeholder="Enter youtube id" />
                  </Form.Item>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default Item;
