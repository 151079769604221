import { DeleteTwoTone, EditTwoTone, EyeTwoTone } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Spin,
  message,
} from "antd";
import Table, { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import IRegion from "../../../../interfaces/IRegion";
import { API_URL } from "../../../../settings";

function Region() {
  var [tableLoadingSpin, setTableSpinLoading] = useState(false);

  const [regionForm] = Form.useForm();
  const [regions, setRegions] = useState<IRegion[]>([]);
  const [regionId, setRegionId] = useState<number>();
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  // Modal related properties
  var [modalLoadingSpin, setModalSpinLoading] = useState(false);
  var [modalState, setModalState] = useState("CREATE");
  const [modalOkButtonText, setModalOkButtonText] = useState("Create");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfirmLoading, setModalConfirmLoading] = useState(false);

  useEffect(() => {
    getRegionList();

    return () => {};
  }, []);

  const getRegionList = () => {
    setTableSpinLoading(true);
    axios
      .get(`${API_URL}/regions`)
      .then((response) => {
        response.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
        });
        setRegions(response.data);
        setTableSpinLoading(false);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
        setTableSpinLoading(false);
      });
  };

  useEffect(() => {
    if (modalState === "CREATE") {
      setModalOkButtonText("Create");
      setIsFormDisabled(false);
      setRegionId(0);
    } else if (modalState === "VIEW") {
      setModalOkButtonText("Change");
      setIsFormDisabled(true);
    } else {
      setModalOkButtonText("Change");
      setIsFormDisabled(false);
    }

    return () => {};
  }, [modalState]);

  const showModal = () => {
    clearModalField();
    setModalOpen(true);
  };

  const clearModalField = () => {
    regionForm.setFieldsValue({
      name: "",
      description: "",
      activeStatus: true,
    });
  };

  const handleCancel = () => {
    setModalOpen(false);
    setModalSpinLoading(false);
    setModalState("CREATE");
  };

  // table rendering settings
  const regionColumns: ColumnsType<IRegion> = [
    {
      title: "Region Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    // {
    //     title: 'Status',
    //     dataIndex: 'status',
    //     key: 'status',
    //     render: (_: any, record: IRegion) => {
    //         if (record.activeStatus) {
    //             return (
    //                 <span>
    //                     <CheckCircleTwoTone twoToneColor="#52c41a" /> Active
    //                 </span>
    //             )
    //         } else {
    //             return (
    //                 <span>
    //                     <CheckCircleTwoTone twoToneColor="#eb2f96" /> InActive
    //                 </span>
    //             )
    //         }

    //     },
    // },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (_: any, record: IRegion) =>
        moment.utc(record.created_at).local().format("DD-MMM-YYYY"),
    },
    {
      title: "Modified Date",
      dataIndex: "lastModifiedDate",
      key: "lastModifiedDate",
      render: (_: any, record: IRegion) =>
        moment.utc(record.updated_at).local().format("DD-MMM-YYYY"),
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: IRegion) => (
        <Space size="middle">
          <a onClick={() => viewAction(record.id)}>
            <EyeTwoTone />
          </a>
          <a onClick={() => updateAction(record.id)}>
            <EditTwoTone />
          </a>
          <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={deletePopConfirm}
            onCancel={deletePopCancel}
            okText="Yes"
            cancelText="No"
          >
            <a onClick={() => deleteRegionAction(record.id)}>
              <DeleteTwoTone />
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const modalFormSubmit = async () => {
    try {
      const values = await regionForm.validateFields();
      console.log("Success:", values);
      setModalConfirmLoading(true);

      if (modalState === "CREATE") {
        axios
          .post(`${API_URL}/regions`, {
            name: regionForm.getFieldValue("name"),
            description: regionForm.getFieldValue("description"),
          })
          .then((response) => {
            setModalOpen(false);
            clearModalField();
            setModalConfirmLoading(false);
            getRegionList();
            console.log(response);
          })
          .catch((err) => {
            // Handle error
            console.log("server error");
            setModalConfirmLoading(false);
          });
      } else {
        axios
          .put(`${API_URL}/regions/${regionId}`, {
            name: regionForm.getFieldValue("name"),
            description: regionForm.getFieldValue("description"),
          })
          .then((response) => {
            clearModalField();
            setModalOpen(false);
            setModalConfirmLoading(false);
            getRegionList();
            setModalState("CREATE");
          })
          .catch((err) => {
            // Handle error
            console.log("server error");
            setModalConfirmLoading(false);
          });
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const deletePopConfirm = (e: any) => {
    axios
      .delete(`${API_URL}/regions/${regionId}`)
      .then((response) => {
        getRegionList();
        message.success("Deleted Successfully.");
      })
      .catch((err) => {
        console.log("server error", err);
      });
  };

  const deletePopCancel = (e: any) => {};

  const updateAction = (id: number) => {
    setRegionId(id);
    setModalState("UPDATE");
    showModal();
    setModalSpinLoading(true);
    axios
      .get(`${API_URL}/regions/${id}`)
      .then((response) => {
        regionForm.setFieldsValue({
          name: response.data.name,
          description: response.data.description,
        });

        setModalSpinLoading(false);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
        setModalSpinLoading(false);
      });
  };

  const deleteRegionAction = (id: number) => {
    setRegionId(id);
  };

  const viewAction = (id: number) => {
    setRegionId(id);
    setModalState("VIEW");
    showModal();
    setModalSpinLoading(true);
    axios
      .get(`${API_URL}/regions/${id}`)
      .then((response) => {
        regionForm.setFieldsValue({
          name: response.data.name,
          description: response.data.description,
        });

        setModalSpinLoading(false);
      })
      .catch((err) => {
        console.log("server error");
        setModalSpinLoading(false);
      });
  };

  return (
    <>
      <Row>
        <Col md={24}>
          <div>
            <Title level={4}>Region</Title>
            <Button type="primary" onClick={showModal}>
              Create
            </Button>
            <Table
              loading={tableLoadingSpin}
              size="small"
              dataSource={regions}
              columns={regionColumns}
              scroll={{ x: "max-content" }} // Enables horizontal scrolling on smaller screens
            />

            <Modal
              title="Region"
              open={modalOpen}
              onOk={modalFormSubmit}
              confirmLoading={modalConfirmLoading}
              onCancel={handleCancel}
              okText={modalOkButtonText}
              okButtonProps={{ disabled: isFormDisabled }}
            >
              <Spin spinning={modalLoadingSpin}>
                <div>
                  <Form
                    name="regionForm"
                    form={regionForm}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    disabled={isFormDisabled}
                  >
                    <Form.Item
                      label="Region Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Region Name can not be null!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item name="description" label="Description">
                      <Input.TextArea />
                    </Form.Item>
                  </Form>
                </div>
              </Spin>
            </Modal>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Region;
