import { Col, Form, Input, Modal, Row, Spin, message } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import IProjectCategory from "../../../../interfaces/IProjectCategory";
import { API_URL } from "../../../../settings";

function ProjectCategory() {
  var [tableLoadingSpin, setTableSpinLoading] = useState(false);

  const [projectCategoryForm] = Form.useForm();
  const [projectCategories, setProjectCategories] = useState<
    IProjectCategory[]
  >([]);
  // const [projectCategory, setProjectCategory] = useState<ProjectCategory>();
  const [departmentId, setProjectCategoryId] = useState<number>();
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  // Modal related properties
  var [modalLoadingSpin, setModalSpinLoading] = useState(false);
  var [modalState, setModalState] = useState("CREATE");
  const [modalOkButtonText, setModalOkButtonText] = useState("Create");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfirmLoading, setModalConfirmLoading] = useState(false);

  useEffect(() => {
    getProjectCategoryList();

    return () => {};
  }, []);

  const getProjectCategoryList = () => {
    setTableSpinLoading(true);
    axios
      .get(`${API_URL}/project-categories`)
      .then((response) => {
        response.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
        });
        setProjectCategories(response.data);
        setTableSpinLoading(false);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
        setTableSpinLoading(false);
      });
  };

  useEffect(() => {
    if (modalState === "CREATE") {
      setModalOkButtonText("Create");
      setIsFormDisabled(false);
      setProjectCategoryId(0);
    } else if (modalState === "VIEW") {
      setModalOkButtonText("Change");
      setIsFormDisabled(true);
    } else {
      setModalOkButtonText("Change");
      setIsFormDisabled(false);
    }

    return () => {};
  }, [modalState]);

  const showModal = () => {
    clearModalField();
    setModalOpen(true);
  };

  const clearModalField = () => {
    projectCategoryForm.setFieldsValue({
      name: "",
      alias: "",
      description: "",
    });
  };

  const handleCancel = () => {
    setModalOpen(false);
    setModalSpinLoading(false);
    setModalState("CREATE");
  };

  // table rendering settings
  const projectCategoryColumns: ColumnsType<IProjectCategory> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (_, record) =>
        moment.utc(record.created_at).local().format("DD-MM-YYYY"),
    },
    {
      title: "Last Modified Date",
      dataIndex: "lastModifiedDate",
      key: "lastModifiedDate",
      render: (_, record) =>
        moment.utc(record.updated_at).local().format("DD-MM-YYYY"),
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <EyeTwoTone onClick={() => viewAction(record.id)} />
    //       <EditTwoTone onClick={() => updateAction(record.id)} />
    //       <Popconfirm
    //         title="Are you sure to delete this record?"
    //         onConfirm={deletePopConfirm}
    //         onCancel={deletePopCancel}
    //         okText="Yes"
    //         cancelText="No"
    //       >
    //         <DeleteTwoTone onClick={() => deleteProjectCategoryAction(record.id)} />
    //       </Popconfirm>
    //     </Space>
    //   ),
    // },
  ];

  const modalFormSubmit = async () => {
    try {
      const values = await projectCategoryForm.validateFields();
      console.log("Success:", values);
      setModalConfirmLoading(true);

      if (modalState === "CREATE") {
        axios
          .post(`${API_URL}/project-categories`, {
            name: projectCategoryForm.getFieldValue("name"),
            alias: projectCategoryForm.getFieldValue("alias"),
            description: projectCategoryForm.getFieldValue("description"),
          })
          .then((response) => {
            setModalOpen(false);
            clearModalField();
            setModalConfirmLoading(false);
            getProjectCategoryList();
            console.log(response);
          })
          .catch((err) => {
            // Handle error
            console.log("server error");
            setModalConfirmLoading(false);
          });
      } else {
        axios
          .put(`${API_URL}/project-categories/${departmentId}`, {
            name: projectCategoryForm.getFieldValue("name"),
            alias: projectCategoryForm.getFieldValue("alias"),
            description: projectCategoryForm.getFieldValue("description"),
          })
          .then((response) => {
            clearModalField();
            setModalOpen(false);
            setModalConfirmLoading(false);
            getProjectCategoryList();
            setModalState("CREATE");
          })
          .catch((err) => {
            // Handle error
            console.log("server error");
            setModalConfirmLoading(false);
          });
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const deletePopConfirm = (e: any) => {
    axios
      .delete(`${API_URL}/project-categories/${departmentId}`)
      .then((response) => {
        getProjectCategoryList();
        message.success("Deleted Successfully.");
      })
      .catch((err) => {
        console.log("server error", err);
      });
  };

  const deletePopCancel = (e: any) => {};

  const updateAction = (id: number) => {
    setProjectCategoryId(id);
    setModalState("UPDATE");
    showModal();
    setModalSpinLoading(true);
    axios
      .get(`${API_URL}/project-categories/${id}`)
      .then((response) => {
        projectCategoryForm.setFieldsValue({
          name: response.data.name,
          alias: response.data.alias,
          description: response.data.description,
        });

        setModalSpinLoading(false);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
        setModalSpinLoading(false);
      });
  };

  const deleteProjectCategoryAction = (id: number) => {
    setProjectCategoryId(id);
  };

  const viewAction = (id: number) => {
    setProjectCategoryId(id);
    setModalState("VIEW");
    showModal();
    setModalSpinLoading(true);
    axios
      .get(`${API_URL}/project-categories/${id}`)
      .then((response) => {
        projectCategoryForm.setFieldsValue({
          name: response.data.name,
          alias: response.data.alias,
          description: response.data.description,
        });

        setModalSpinLoading(false);
      })
      .catch((err) => {
        console.log("server error");
        setModalSpinLoading(false);
      });
  };

  return (
    <>
      <Row>
        <Col md={24}>
          <div>
            <Title level={4}>Project Category</Title>
            {/* <Button type="primary" onClick={showModal}>Create</Button> */}
            <Table
              loading={tableLoadingSpin}
              size="small"
              dataSource={projectCategories}
              columns={projectCategoryColumns}
              scroll={{ x: "max-content" }} // Enables horizontal scrolling on smaller screens
            />

            <Modal
              title="ProjectCategory"
              open={modalOpen}
              onOk={modalFormSubmit}
              confirmLoading={modalConfirmLoading}
              onCancel={handleCancel}
              okText={modalOkButtonText}
              okButtonProps={{ disabled: isFormDisabled }}
            >
              <Spin spinning={modalLoadingSpin}>
                <div>
                  <Form
                    name="projectCategoryForm"
                    form={projectCategoryForm}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    disabled={isFormDisabled}
                  >
                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[
                        { required: true, message: "Name can not be null!" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item name="description" label="Description">
                      <Input.TextArea />
                    </Form.Item>
                  </Form>
                </div>
              </Spin>
            </Modal>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default ProjectCategory;
