import { DeleteTwoTone, EditTwoTone, EyeTwoTone } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Spin,
  message,
} from "antd";
import Table, { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import IPlotPosition from "../../../../interfaces/IPlotPosition";
import { API_URL } from "../../../../settings";

function PlotPosition() {
  var [tableLoadingSpin, setTableSpinLoading] = useState(false);

  const [plotPositionForm] = Form.useForm();
  const [plotPositions, setPlotPositions] = useState<IPlotPosition[]>([]);
  // const [plotPosition, setPlotPosition] = useState<PlotPosition>();
  const [plotPositionId, setPlotPositionId] = useState<number>();
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  // Modal related properties
  var [modalLoadingSpin, setModalSpinLoading] = useState(false);
  var [modalState, setModalState] = useState("CREATE");
  const [modalOkButtonText, setModalOkButtonText] = useState("Create");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfirmLoading, setModalConfirmLoading] = useState(false);

  useEffect(() => {
    getPlotPositionList();

    return () => {};
  }, []);

  const getPlotPositionList = () => {
    setTableSpinLoading(true);
    axios
      .get(`${API_URL}/plot-positions`)
      .then((response) => {
        response.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
        });
        setPlotPositions(response.data);
        setTableSpinLoading(false);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
        setTableSpinLoading(false);
      });
  };

  useEffect(() => {
    if (modalState === "CREATE") {
      setModalOkButtonText("Create");
      setIsFormDisabled(false);
      setPlotPositionId(0);
    } else if (modalState === "VIEW") {
      setModalOkButtonText("Change");
      setIsFormDisabled(true);
    } else {
      setModalOkButtonText("Change");
      setIsFormDisabled(false);
    }

    return () => {};
  }, [modalState]);

  const showModal = () => {
    clearModalField();
    setModalOpen(true);
  };

  const clearModalField = () => {
    plotPositionForm.setFieldsValue({
      name: "",
      description: "",
      activeStatus: true,
    });
  };

  const handleCancel = () => {
    setModalOpen(false);
    setModalSpinLoading(false);
    setModalState("CREATE");
  };

  // table rendering settings
  const plotPositionColumns: ColumnsType<IPlotPosition> = [
    {
      title: "Plot Position Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (_: any, record: IPlotPosition) =>
        moment.utc(record.created_at).local().format("DD-MMM-YYYY"),
    },
    {
      title: "Modified Date",
      dataIndex: "lastModifiedDate",
      key: "lastModifiedDate",
      render: (_: any, record: IPlotPosition) =>
        moment.utc(record.updated_at).local().format("DD-MMM-YYYY"),
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: IPlotPosition) => (
        <Space size="middle">
          <EyeTwoTone onClick={() => viewAction(record.id)} />
          <EditTwoTone onClick={() => updateAction(record.id)} />
          <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={deletePopConfirm}
            onCancel={deletePopCancel}
            okText="Yes"
            cancelText="No"
          >
            <DeleteTwoTone
              onClick={() => deletePlotPositionAction(record.id)}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const modalFormSubmit = async () => {
    try {
      const values = await plotPositionForm.validateFields();
      console.log("Success:", values);
      setModalConfirmLoading(true);

      if (modalState === "CREATE") {
        axios
          .post(`${API_URL}/plot-positions`, {
            name: plotPositionForm.getFieldValue("name"),
            description: plotPositionForm.getFieldValue("description"),
          })
          .then((response) => {
            setModalOpen(false);
            clearModalField();
            setModalConfirmLoading(false);
            getPlotPositionList();
            console.log(response);
          })
          .catch((err) => {
            // Handle error
            console.log("server error");
            setModalConfirmLoading(false);
          });
      } else {
        axios
          .put(`${API_URL}/plot-positions/${plotPositionId}`, {
            name: plotPositionForm.getFieldValue("name"),
            description: plotPositionForm.getFieldValue("description"),
          })
          .then((response) => {
            clearModalField();
            setModalOpen(false);
            setModalConfirmLoading(false);
            getPlotPositionList();
            setModalState("CREATE");
          })
          .catch((err) => {
            // Handle error
            console.log("server error");
            setModalConfirmLoading(false);
          });
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const deletePopConfirm = (e: any) => {
    axios
      .delete(`${API_URL}/plot-positions/${plotPositionId}`)
      .then((response) => {
        getPlotPositionList();
        message.success("Deleted Successfully.");
      })
      .catch((err) => {
        console.log("server error", err);
      });
  };

  const deletePopCancel = (e: any) => {};

  const updateAction = (id: number) => {
    setPlotPositionId(id);
    setModalState("UPDATE");
    showModal();
    setModalSpinLoading(true);
    axios
      .get(`${API_URL}/plot-positions/${id}`)
      .then((response) => {
        plotPositionForm.setFieldsValue({
          name: response.data.name,
          description: response.data.description,
        });

        setModalSpinLoading(false);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
        setModalSpinLoading(false);
      });
  };

  const deletePlotPositionAction = (id: number) => {
    setPlotPositionId(id);
  };

  const viewAction = (id: number) => {
    setPlotPositionId(id);
    setModalState("VIEW");
    showModal();
    setModalSpinLoading(true);
    axios
      .get(`${API_URL}/plot-positions/${id}`)
      .then((response) => {
        plotPositionForm.setFieldsValue({
          name: response.data.name,
          description: response.data.description,
        });

        setModalSpinLoading(false);
      })
      .catch((err) => {
        console.log("server error", err);
        setModalSpinLoading(false);
      });
  };

  return (
    <>
      <Row>
        <Col md={24}>
          <div>
            <Title level={4}>Plot Position</Title>
            <Button type="primary" onClick={showModal}>
              Create
            </Button>
            <Table
              loading={tableLoadingSpin}
              size="small"
              dataSource={plotPositions}
              columns={plotPositionColumns}
              scroll={{ x: "max-content" }} // Enables horizontal scrolling on smaller screens
            />

            <Modal
              title="PlotPosition"
              open={modalOpen}
              onOk={modalFormSubmit}
              confirmLoading={modalConfirmLoading}
              onCancel={handleCancel}
              okText={modalOkButtonText}
              okButtonProps={{ disabled: isFormDisabled }}
            >
              <Spin spinning={modalLoadingSpin}>
                <div>
                  <Form
                    name="plotPositionForm"
                    form={plotPositionForm}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    disabled={isFormDisabled}
                  >
                    <Form.Item
                      label="Plot Position Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Plot Position Name can not be null!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item name="description" label="Description">
                      <Input.TextArea />
                    </Form.Item>
                    {/* <Form.Item
                      name="activeStatus"
                      label="Active"
                      valuePropName="checked">
                      <Switch />
                    </Form.Item> */}
                  </Form>
                </div>
              </Spin>
            </Modal>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default PlotPosition;
