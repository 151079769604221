import { EditTwoTone } from "@ant-design/icons";
import { Button, Col, Row, Space, message } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IGallery from "../../../interfaces/IGallery";
import { API_URL } from "../../../settings";

function Companies() {
  var [tableLoadingSpin, setTableSpinLoading] = useState(false);
  const [companies, setCsrs] = useState<IGallery[]>([]);

  useEffect(() => {
    getCompanyList();

    return () => {};
  }, []);

  const getCompanyList = () => {
    setTableSpinLoading(true);
    axios
      .get(`${API_URL}/companies`)
      .then((response) => {
        response.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
        });
        setCsrs(response.data);
        setTableSpinLoading(false);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
        setTableSpinLoading(false);
      });
  };

  const deletePopConfirm = (companyId: any) => {
    axios
      .delete(`${API_URL}/companies/${companyId}`)
      .then((response) => {
        getCompanyList();
        message.success("Deleted Successfully.");
      })
      .catch((err) => {
        console.log("server error", err);
      });
  };

  const viewAction = (id: number) => {};
  // table rendering settings
  const companyColumns: ColumnsType<IGallery> = [
    // {
    //     title: 'Company Id',
    //     dataIndex: 'company_id',
    //     key: 'company_id',
    // },
    {
      title: "Name",
      dataIndex: "company_name",
      key: "title",
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (_: any, record: IGallery) =>
        moment.utc(record.created_at).local().format("DD-MMM-YYYY"),
    },
    {
      title: "Modified Date",
      dataIndex: "lastModifiedDate",
      key: "lastModifiedDate",
      render: (_: any, record: IGallery) =>
        moment.utc(record.updated_at).local().format("DD-MMM-YYYY"),
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: IGallery) => (
        <Space size="middle">
          <Link to={`/companies/${record.id}`}>
            <EditTwoTone />
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col md={24}>
          <div>
            <Title level={4}>Company List</Title>
            <Link to={"/company"}>
              <Button type="primary">Create</Button>
            </Link>
            <Table
              loading={tableLoadingSpin}
              size="small"
              dataSource={companies}
              columns={companyColumns}
              scroll={{ x: 'max-content' }} // Enables horizontal scrolling on smaller screens
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Companies;
