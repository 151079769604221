import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Upload,
  UploadFile,
  message,
  notification,
} from "antd";
import { RcFile, UploadProps } from "antd/es/upload";
import axios from "axios";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { API_URL } from "../../../settings";
import ICompanySlider from "../../../interfaces/ICompanySlider";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

function Company() {
  const navigate = useNavigate();
  const [notificationApi, contextHolder] = notification.useNotification();
  //   const [notificationApi, contextHolder] = notification.useNotification();

  const [companyCategoryId, setProjectCategoryId] = useState(1);
  const [sliderLoading, setSliderLoading] = React.useState<boolean>(false);
  var [formState, setFormState] = useState("CREATE");
  const [formSubmitButtonText, setFormSubmitButtonText] = useState("Create");

  const [companyLoading, setCompanyLoading] = React.useState<boolean>(false);
  const [companyForm] = Form.useForm();
  const [companyLogoImageName, setCompanyLogoImageName] = useState("");
  const { Dragger } = Upload;
  const [companySliders, setCompanySliders] = useState<ICompanySlider[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [branCompanyfileList, setBranCompanyFileList] = useState<UploadFile[]>(
    []
  );

  const handleRemoveCompanySlider = async (file: UploadFile) => {
    setCompanySliders(companySliders.filter((x) => x.image_name !== file.name));
    return true;
  };

  const onResetCompanyForm = () => {
    companyForm.resetFields();
  };

  let { id } = useParams();

  const onFinishCompanyForm = (values: any) => {
    setCompanyLoading(true);
    if (!id) {
      axios
        .post(`${API_URL}/companies`, {
          company_name: companyForm.getFieldValue("company_name"),
          company_name_bangla: companyForm.getFieldValue("company_name_bangla"),
          company_alias: companyForm.getFieldValue("company_alias"),
          company_alias_bangla: companyForm.getFieldValue(
            "company_alias_bangla"
          ),
          company_slogan: companyForm.getFieldValue("company_slogan"),
          company_addr_1: companyForm.getFieldValue("company_addr_1"),
          company_addr_2: companyForm.getFieldValue("company_addr_2"),
          company_add_1_bangla: companyForm.getFieldValue(
            "company_add_1_bangla"
          ),
          company_add_2_bangla: companyForm.getFieldValue(
            "company_add_2_bangla"
          ),
          company_country: companyForm.getFieldValue("company_country"),
          company_mobile: companyForm.getFieldValue("company_mobile"),
          company_phone: companyForm.getFieldValue("company_phone"),
          hotline_no: companyForm.getFieldValue("hotline_no"),
          company_fax: companyForm.getFieldValue("company_fax"),
          company_email: companyForm.getFieldValue("company_email"),
          company_website: companyForm.getFieldValue("company_website"),
          tin_no: companyForm.getFieldValue("tin_no"),
          vat_reg_no: companyForm.getFieldValue("vat_reg_no"),
          erc_no: companyForm.getFieldValue("erc_no"),
          erc_date: companyForm.getFieldValue("erc_date"),
          irc_no: companyForm.getFieldValue("irc_no"),
          license_no: companyForm.getFieldValue("license_no"),
          company_latest_news: companyForm.getFieldValue("company_latest_news"),
          office_1_address: companyForm.getFieldValue("office_1_address"),
          office_1_telephone_no: companyForm.getFieldValue(
            "office_1_telephone_no"
          ),
          office_1_mobile_no: companyForm.getFieldValue("office_1_mobile_no"),
          office_1_email: companyForm.getFieldValue("office_1_email"),
          office_1_addr_name: companyForm.getFieldValue("office_1_addr_name"),
          office_2_address: companyForm.getFieldValue("office_2_address"),
          office_2_telephone_no: companyForm.getFieldValue(
            "office_2_telephone_no"
          ),
          office_2_mobile_no: companyForm.getFieldValue("office_2_mobile_no"),
          office_2_email: companyForm.getFieldValue("office_2_email"),
          office_2_addr_name: companyForm.getFieldValue("office_2_addr_name"),
          office_3_address: companyForm.getFieldValue("office_3_address"),
          office_3_telephone_no: companyForm.getFieldValue(
            "office_3_telephone_no"
          ),
          office_3_mobile_no: companyForm.getFieldValue("office_3_mobile_no"),
          office_3_email: companyForm.getFieldValue("office_3_email"),
          office_3_addr_name: companyForm.getFieldValue("office_3_addr_name"),
          company_logo_name: companyLogoImageName,
          active_stat: companyForm.getFieldValue("active_stat"),
          company_video_link: companyForm.getFieldValue("company_video_link"),
          company_sliders: companySliders,
        })
        .then((response) => {
          navigate("/companies");
        })
        .catch((err) => {
          setCompanyLoading(false);
          console.log("server error", err);
        });
    } else {
      axios
        .put(`${API_URL}/companies/${id}`, {
          company_name: companyForm.getFieldValue("company_name"),
          company_name_bangla: companyForm.getFieldValue("company_name_bangla"),
          company_alias: companyForm.getFieldValue("company_alias"),
          company_alias_bangla: companyForm.getFieldValue(
            "company_alias_bangla"
          ),
          company_slogan: companyForm.getFieldValue("company_slogan"),
          company_addr_1: companyForm.getFieldValue("company_addr_1"),
          company_addr_2: companyForm.getFieldValue("company_addr_2"),
          company_add_1_bangla: companyForm.getFieldValue(
            "company_add_1_bangla"
          ),
          company_add_2_bangla: companyForm.getFieldValue(
            "company_add_2_bangla"
          ),
          company_country: companyForm.getFieldValue("company_country"),
          company_mobile: companyForm.getFieldValue("company_mobile"),
          hotline_no: companyForm.getFieldValue("hotline_no"),
          company_phone: companyForm.getFieldValue("company_phone"),
          company_fax: companyForm.getFieldValue("company_fax"),
          company_email: companyForm.getFieldValue("company_email"),
          company_website: companyForm.getFieldValue("company_website"),
          tin_no: companyForm.getFieldValue("tin_no"),
          vat_reg_no: companyForm.getFieldValue("vat_reg_no"),
          erc_no: companyForm.getFieldValue("erc_no"),
          erc_date: companyForm.getFieldValue("erc_date"),
          irc_no: companyForm.getFieldValue("irc_no"),
          license_no: companyForm.getFieldValue("license_no"),
          company_latest_news: companyForm.getFieldValue("company_latest_news"),
          office_1_address: companyForm.getFieldValue("office_1_address"),
          office_1_telephone_no: companyForm.getFieldValue(
            "office_1_telephone_no"
          ),
          office_1_mobile_no: companyForm.getFieldValue("office_1_mobile_no"),
          office_1_email: companyForm.getFieldValue("office_1_email"),
          office_1_addr_name: companyForm.getFieldValue("office_1_addr_name"),
          office_2_address: companyForm.getFieldValue("office_2_address"),
          office_2_telephone_no: companyForm.getFieldValue(
            "office_2_telephone_no"
          ),
          office_2_mobile_no: companyForm.getFieldValue("office_2_mobile_no"),
          office_2_email: companyForm.getFieldValue("office_2_email"),
          office_2_addr_name: companyForm.getFieldValue("office_2_addr_name"),
          office_3_address: companyForm.getFieldValue("office_3_address"),
          office_3_telephone_no: companyForm.getFieldValue(
            "office_3_telephone_no"
          ),
          office_3_mobile_no: companyForm.getFieldValue("office_3_mobile_no"),
          office_3_email: companyForm.getFieldValue("office_3_email"),
          office_3_addr_name: companyForm.getFieldValue("office_3_addr_name"),
          company_logo_name: companyLogoImageName,
          active_stat: companyForm.getFieldValue("active_stat"),
          company_video_link: companyForm.getFieldValue("company_video_link"),
          company_sliders: companySliders,
        })
        .then((response) => {
          navigate("/companies");
        })
        .catch((err) => {
          setCompanyLoading(false);
          console.log("server error", err);
        });
    }
    setCompanyLoading(false);
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleRemoveBrandCompanySlider = async (file: UploadFile) => {
    setCompanyLogoImageName("");
    setBranCompanyFileList([]);
    return true;
  };

  const getCompany = () => {
    setCompanyLoading(true);
    axios
      .get(`${API_URL}/companies/${id}`)
      .then((response) => {
        setCompanyLogoImageName(response.data.company_logo_name);
        companyForm.setFieldsValue({
          company_name: response.data.company_name,
          company_name_bangla: response.data.company_name_bangla,
          company_alias: response.data.company_alias,
          company_alias_bangla: response.data.company_alias_bangla,
          company_slogan: response.data.company_slogan,
          company_addr_1: response.data.company_addr_1,
          company_addr_2: response.data.company_addr_2,
          company_add_1_bangla: response.data.company_add_1_bangla,
          company_add_2_bangla: response.data.company_add_2_bangla,
          company_mobile: response.data.company_mobile,
          company_phone: response.data.company_phone,
          hotline_no: response.data.hotline_no,
          company_email: response.data.company_email,
          company_latest_news: response.data.company_latest_news,
          tin_no: response.data.tin_no,
          office_1_address: response.data.office_1_address,
          office_1_telephone_no: response.data.office_1_telephone_no,
          office_1_mobile_no: response.data.office_1_mobile_no,
          office_1_email: response.data.office_1_email,
          office_2_address: response.data.office_2_address,
          office_2_telephone_no: response.data.office_2_telephone_no,
          office_2_mobile_no: response.data.office_2_mobile_no,
          office_2_email: response.data.office_2_email,
          office_3_address: response.data.office_3_address,
          office_3_telephone_no: response.data.office_3_telephone_no,
          office_3_mobile_no: response.data.office_3_mobile_no,
          office_3_email: response.data.office_3_email,
          office_1_addr_name: response.data.office_1_addr_name,
          office_2_addr_name: response.data.office_2_addr_name,
          office_3_addr_name: response.data.office_3_addr_name,
          active_stat: response.data.active_stat,
        });
        if (response.data.company_logo_name) {
          setBranCompanyFileList([
            {
              uid: response.data.id,
              name: response.data.company_logo_name,
              status: "done",
              url:
                API_URL + "/image-download/" + response.data.company_logo_name,
            },
          ]);
        }

        companyForm.setFieldsValue({
          title: response.data.title,
          description: response.data.description,
        });

        response.data.company_sliders.map(
          (x: { [x: string]: any; id: any }) => {
            x["key"] = x.id;
            x["uid"] = x.id;
            x["name"] = x.image_name;
            x["status"] = "done";
            x["url"] = API_URL + "/image-download/" + x.image_name;
          }
        );

        setFileList(response.data.company_sliders);
        setCompanySliders(response.data.company_sliders);
        setCompanyLoading(false);
        setFormSubmitButtonText("Change");
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
        setCompanyLoading(false);
      });
  };

  const onResetProjectForm = () => {
    companyForm.resetFields();
  };

  useEffect(() => {
    onResetProjectForm();
    if (id) {
      getCompany();
      setFormState("UPDATE");
    }
    return () => {};
  }, []);

  const handleChange: UploadProps["onChange"] = (info) => {
    setFileList(info.fileList);
    const { status } = info.file;

    if (
      info.file.percent === 100 &&
      status === "done" &&
      info.file.response &&
      info.file.response.file_name
    ) {
      const companySliderObject = {
        image_name: info.file.response.file_name,
        company_id: id ? parseInt(id) : undefined,
      };
      setCompanySliders((prevState) => [...prevState, companySliderObject]);
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const companyThumnailprops: UploadProps = {
    name: "file",
    // beforeUpload: beforeUpload,
    fileList: branCompanyfileList,
    // onPreview: handlePreview,
    action: `${API_URL}/image-upload`,
    onRemove: handleRemoveBrandCompanySlider,

    onChange(info) {
      setBranCompanyFileList(info.fileList);
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (
        info.file.percent === 100 &&
        status === "done" &&
        info.file.response &&
        info.file.response.file_name
      ) {
        setCompanyLogoImageName(info.file.response.file_name);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {},
  };

  return (
    <>
      {contextHolder}
      <Form
        layout="vertical"
        name="realEstateConfigForm"
        form={companyForm}
        initialValues={{ remember: 1 }}
        autoComplete="off"
        onFinish={onFinishCompanyForm}
      >
        <Row gutter={10}>
          <Col span={15}>
            <Card title="Company Information" bordered={true}>
              <Spin spinning={companyLoading}>
                <Row gutter={10}>
                  <Col span={8}>
                    <Form.Item
                      name="company_name"
                      label="Company Name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder="Company Name" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="company_name_bangla"
                      label="Name (Bangla)"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input placeholder="Name" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="company_alias"
                      label="Alias"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder="Alias" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="company_alias_bangla"
                      label="Alias (Bangla)"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input placeholder="Alias" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      name="company_slogan"
                      label="Slogan"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input placeholder="Slogan" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      name="company_mobile"
                      label="Mobile"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input placeholder="Mobile" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      name="company_phone"
                      label="Phone"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input placeholder="Phone" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="hotline_no"
                      label="Hotline"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input placeholder="Hotline" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="company_latest_news"
                      label="News Headline"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input.TextArea placeholder="" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      name="company_email"
                      label="Email"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="tin_no"
                      label="TIN"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input placeholder="TIN" />
                    </Form.Item>
                  </Col>

                  {/* <Col span={8}>
                    <Form.Item
                      name="active_stat"
                      label="Active Status"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      initialValue={0}
                    >
                      <Select
                        options={[
                          { value: 0, label: "Inactive" },
                          { value: 1, label: "Active" },
                        ]}
                      />
                    </Form.Item>
                  </Col> */}
                </Row>
                <Divider orientation="center" plain>
                  Office 1
                </Divider>
                <Row gutter={10}>
                  <Col span={8}>
                    <Form.Item name="office_1_addr_name" label="Name">
                      <Input placeholder="Name" />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item name="office_1_address" label="Address">
                      <Input placeholder="Address" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="office_1_telephone_no"
                      label="Telephone No"
                    >
                      <Input placeholder="Telephone No" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="office_1_mobile_no" label="Mobile No">
                      <Input placeholder="Mobile No" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="office_1_email" label="Email">
                      <Input placeholder="Email" />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider orientation="center" plain>
                  Office 2
                </Divider>
                <Row gutter={10}>
                  <Col span={8}>
                    <Form.Item name="office_2_addr_name" label="Name">
                      <Input placeholder="Name" />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item name="office_2_address" label="Address">
                      <Input placeholder="Address" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="office_2_telephone_no"
                      label="Telephone No"
                    >
                      <Input placeholder="Telephone No" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="office_2_mobile_no" label="Mobile No">
                      <Input placeholder="Mobile No" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="office_2_email" label="Email">
                      <Input placeholder="Email" />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider orientation="center" plain>
                  Office 3
                </Divider>
                <Row gutter={10}>
                  <Col span={8}>
                    <Form.Item name="office_3_addr_name" label="Name">
                      <Input placeholder="Name" />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item name="office_3_address" label="Address">
                      <Input placeholder="Address" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="office_3_telephone_no"
                      label="Telephone No"
                    >
                      <Input placeholder="Telephone No" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="office_3_mobile_no" label="Mobile No">
                      <Input placeholder="Mobile No" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="office_3_email" label="Email">
                      <Input placeholder="Email" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item>
                      <Space>
                        <Button type="primary" htmlType="submit">
                          {formSubmitButtonText}
                        </Button>
                        {!id && (
                          <Button
                            htmlType="button"
                            onClick={onResetCompanyForm}
                          >
                            Reset
                          </Button>
                        )}

                        <Link
                          className="text-decoration-none"
                          to={"/companies"}
                        >
                          {" "}
                          Cancel
                        </Link>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Spin>
            </Card>
          </Col>
          <Col span={9}>
            <Row className="mb-2">
              <Col span={24}>
                <Card title="Logo" bordered={true}>
                  <Dragger
                    maxCount={1}
                    listType="picture-card"
                    {...companyThumnailprops}
                    accept={"image/*"}
                    multiple={false}
                    showUploadList={{
                      showPreviewIcon: true,
                      showRemoveIcon: true,
                      showDownloadIcon: true,
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Support for a single image upload. Strictly prohibited
                      from uploading company data or other banned files.
                    </p>
                  </Dragger>
                  <Modal
                    open={previewOpen}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <img
                      alt="slider"
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  </Modal>
                </Card>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col span={24}>
                <Card title="Sliders" bordered={true}>
                  <Spin spinning={sliderLoading}>
                    <div>
                      <Upload
                        action={`${API_URL}/image-upload`}
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onRemove={handleRemoveCompanySlider}
                        onChange={handleChange}
                        accept={"image/*"}
                        maxCount={8}
                        multiple={true}
                        showUploadList={{
                          showPreviewIcon: true,
                          showRemoveIcon: true,
                          showDownloadIcon: true,
                        }}
                      >
                        {fileList.length >= 8 ? null : uploadButton}
                      </Upload>
                      <Modal
                        open={previewOpen}
                        footer={null}
                        onCancel={handleCancel}
                      >
                        <img
                          alt="slider"
                          style={{ width: "100%" }}
                          src={previewImage}
                        />
                      </Modal>
                    </div>
                  </Spin>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default Company;
