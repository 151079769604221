import { Button, Col, Form, Input, Row, Spin } from 'antd';
import Title from 'antd/es/typography/Title';
import axios from 'axios';
import React, { useEffect } from 'react';
import { API_URL } from '../../../../settings';
function Configuration() {

  useEffect(() => {
    setLoading(true);
    axios.get(`${API_URL}/rl-configurations/1`)
      .then((response) => {

        realEstateConfigForm.setFieldsValue({
          display_feature_list_number: response.data.display_feature_list_number,
          contact_mail_send_to: response.data.contact_mail_send_to,
          contact_mail_send_cc: response.data.contact_mail_send_cc,
          contact_mail_send_bcc: response.data.contact_mail_send_bcc,
        });
        setLoading(false);

      }).catch(err => {
        console.log("server error", err);
        setLoading(false);
      });

    return () => {

    }
  }, []);


  const onFinish = (values: any) => {
    setLoading(true);
    console.log('Success:', values);
    axios.put(`${API_URL}/rl-configurations/1`, {
      display_feature_list_number: realEstateConfigForm.getFieldValue("display_feature_list_number"),
      contact_mail_send_to: realEstateConfigForm.getFieldValue("contact_mail_send_to"),
      contact_mail_send_cc: realEstateConfigForm.getFieldValue("contact_mail_send_cc"),
      contact_mail_send_bcc: realEstateConfigForm.getFieldValue("contact_mail_send_bcc"),
    })
      .then((response) => {
        setLoading(false);
        console.log(response);
      })
      .catch((err) => {
        console.log("server error", err);
        setLoading(false);
      });
  };


  const [loading, setLoading] = React.useState<boolean>(false);


  const [realEstateConfigForm] = Form.useForm();


  return (
    <>
      <Row>
        <Col md={24}>
          <div>
            <Title level={4}>Real Estate Configuration</Title>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <Spin
            spinning={loading}>
            <Form
              layout="vertical"
              name="realEstateConfigForm"
              form={realEstateConfigForm}
              initialValues={{ remember: true }}
              autoComplete="off"
              onFinish={onFinish}
            >
              <Form.Item
                label="Features Property List No"
                name="display_feature_list_number"
                rules={[{ required: true, message: 'display_feature_list_number can not be null!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Contact Message Send To"
                name="contact_mail_send_to"
                rules={[{ required: true, message: 'display_feature_list_number can not be null!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Contact Message Send CC"
                name="contact_mail_send_cc"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Contact Message Send BCC"
                name="contact_mail_send_bcc"
              >
                <Input />
              </Form.Item>
              <Form.Item >
                <Button type="primary" htmlType="submit">
                  Change
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Col>

      </Row>
    </>

  )
}

export default Configuration;