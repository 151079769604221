import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Button, Card, Col, Row, Spin, message } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { API_URL } from '../../settings';
function MissionAndVision() {
    const [aboutUsId, setAboutUsId] = useState();
    const [content, setContent] = useState<string>();
    const [type, setType] = useState('MISSION_VISION_VALUE');
    const [title, setTitle] = useState();

    const [apiLoader, setApiLoader] = useState(false);


    useEffect(() => {
        getCorporateProfile();
        return () => {

        }

    }, []);

    const getCorporateProfile = () => {
        setApiLoader(true);
        axios.get(`${API_URL}/about-us-by-type/${type}`)
            .then((response) => {
                setApiLoader(false);
                setContent(response.data.description);
                setAboutUsId(response.data.id);
                setTitle(response.data.title);
                response.data.map((x: { [x: string]: any; id: any; }) => {
                    x['key'] = x.id;
                })
            }).catch(err => {
                // Handle error
                console.log("server error");
                setApiLoader(false);
            });
    }

    const onSubmitForm = () => {
        setApiLoader(true);

        if (aboutUsId) {
            axios.put(`${API_URL}/about-us/${aboutUsId}`, {
                type: type,
                title: type,
                description: content,
            }).then((response) => {
                message.success('Successfully updated.');
                setApiLoader(false);

            }).catch(err => {
                // Handle error
                console.log("server error");
                setApiLoader(false);
            });
        } else {
            axios.post(`${API_URL}/about-us`, {
                type: type,
                title: type,
                description: content,
            }).then((response) => {
                setAboutUsId(response.data.id);
                message.success('Successfully created.');
                setApiLoader(false);
            }).catch(err => {
                // Handle error
                console.log("server error");
                setApiLoader(false);
            });
        }

    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <Card title="Corporate Profile" bordered={true}>
                        <Spin spinning={apiLoader}>
                            <CKEditor
                                editor={ClassicEditor}
                                data={content}
                                onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    console.log(data);
                                    setContent(data);
                                    // console.log({ event, editor, data });
                                    // setContent(editor.getData()['data'])
                                }}
                                onBlur={(event, editor) => {
                                    console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                    console.log('Focus.', editor);
                                }}
                            />
                            <Button type='primary' className='mt-3' onClick={onSubmitForm}>Submit</Button>
                        </Spin>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default MissionAndVision;